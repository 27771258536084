import React, { useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import {
  quoteApi,
  useFormControl,
  Joi,
  useApi,
  SingleAttachment,
  useAuth,
} from "component-library";
import PageLoader from "../../../_app/_module/PageLoader";
import { DateTime } from "luxon";

const useAddQuote = ({ bid, setOpen, setQuote, quote }) => {
  const [attachment, setAttachment] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!!quote && !!quote.file) {
      setAttachment({ ...quote.file, name: quote.file.file_name });
    }
  }, [quote]);

  const formatDateToString = (date) => {
    return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
  };

  const {
    request: addQuoteRequest,
    loading: addQuoteLoading,
    error: addQuoteError,
  } = useApi(quoteApi.addQuote);

  const {
    request: updateQuoteRequest,
    loading: updateQuoteLoading,
    error: updateQuoteError,
  } = useApi(quoteApi.updateQuote);

  const {
    request: addFileRequest,
    loading: addFileLoading,
    error: addFileError,
  } = useApi(quoteApi.addAttachment);

  const {
    request: removeFileRequest,
    loading: removeFileLoading,
    error: removeFileError,
  } = useApi(quoteApi.removeQuoteFile);

  const currencyRegex = /^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,4})?$/;

  const schema = {
    expiration_date: Joi.date().required(),
    quote_date: Joi.date().required(),
    acknowledgment: Joi.checkbox(),
    quote_amount: Joi.string().regex(currencyRegex).required(),
  };

  const removeAttachment = async (file) => {
    if (!!quote) {
      const { success } = await removeFileRequest({
        quote_id: quote.quote_id,
        file_id: quote.file_id,
        user_id: user.id,
      });
      if (success) {
        setAttachment();
        setQuote({ ...quote, file_id: null, file: null });
      }
    } else {
      setAttachment();
    }
  };

  const addAttachment = async (file) => {
    if (!!quote) {
      const { success, responseData } = await addFileRequest({
        quote_id: quote.quote_id,
        user_id: user.id,
        file: file,
      });
      if (success) {
        setAttachment({
          ...responseData.quote.file,
          name: responseData.quote.file.filename,
          file_name: responseData.quote.file.filename,
          abs_url: responseData.quote.file.file_url,
          url: responseData.quote.file.file_url,
        });
        setQuote({ ...quote, file_id: responseData.quote.file.id });
      }
    } else {
      setAttachment(file);
    }
  };

  const initialData = !!quote
    ? {
        expiration_date: quote.quote_expiration_date,
        quote_date: quote.quote_date,
        quote_amount: quote.amount,
        acknowledgment: 0,
      }
    : {
        quote_amount: "",
        expiration_date: "",
        quote_date: new Date(),
        acknowledgment: 0,
      };

  const onSubmit = async (formData, setLoading) => {
    if (!!quote) {
      const { success, responseData } = await updateQuoteRequest({
        id: quote.quote_id,
        amount: formData.quote_amount,
        expiration_date: formatDateToString(formData.expiration_date),
        quote_date: formatDateToString(formData.quote_date),
      });
      if (success) {
        setQuote({
          ...responseData.quote,
          file: {
            ...responseData.quote.file,
            file_name: responseData.quote.file.filename,
            url: responseData.quote.file.file_url,
          },
        });
        setOpen(false);
      } else {
        setLoading(false);
      }
    } else {
      const quoteObject = {
        user_id: user.id,
        file: attachment,
        quote: {
          amount: formData.quote_amount,
          quote_expiration_date: formData.expiration_date,
          quote_date: formData.quote_date,
        },
      };
      const { success, responseData } = await addQuoteRequest({
        projectId: bid.id,
        quote: quoteObject,
      });

      setLoading(false);

      if (success) {
        setQuote({
          ...responseData.quote,
          file_id: responseData.quote.file.id,
          quote_id: responseData.quote.id,
          quote_expiration_date: responseData.quote.expiration_date,
        });
        setAttachment(null);
        setCanSubmit(false);
        setOpen(false);
      }
    }
  };

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderCheckbox,
    data,
  } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  useEffect(() => {
    if (data.acknowledgment) {
      if (!!attachment && !!data.quote_amount && !!data.expiration_date) {
        setCanSubmit(true);
      } else {
        setCanSubmit(false);
      }
    } else {
      setCanSubmit(false);
    }
  }, [data, attachment]);

  const fields = (
    <div className="position-relative">
      <div className="row">
        <div className="col-12">
          {!!addQuoteLoading && <PageLoader />}
          {!!updateQuoteLoading && <PageLoader />}
          {!!addQuoteError && (
            <Alert dismissible variant="danger">
              {addQuoteError}
            </Alert>
          )}
          {!!updateQuoteError && (
            <Alert dismissible variant="danger">
              {updateQuoteError}
            </Alert>
          )}
          {!!addFileError && (
            <Alert dismissible variant="danger">
              {addFileError}
            </Alert>
          )}
          {!!removeFileError && (
            <Alert dismissible variant="danger">
              {removeFileError}
            </Alert>
          )}

          <p>Upload a file to submit a quote</p>
        </div>
        <div className="col">
          <div>Attach File</div>

          <SingleAttachment
            attachment={attachment}
            addAttachment={addAttachment}
            fileType={["Bid Document"]}
            loading={addFileLoading || removeFileLoading}
            removeAttachment={removeAttachment}
            canUpload={true}
            canEdit={true}
            hideHistory={true}
            hideInternal={true}
          />
        </div>
        <div className="col-4">
          {renderTextField({
            name: "quote_date",
            label: "Quote Date",
            type: "date",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-5">
          {renderTextField({
            name: "quote_amount",
            label: "Quote Amount",
            format: "currency",
          })}
          {renderTextField({
            name: "expiration_date",
            label: "Quote Expiration Date",
            type: "date",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex justify-content-end">
          {renderCheckbox({
            name: "acknowledgment",
            label: "I understand that I am submitting a quote for this bid",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col d-flex justify-content-end">
          <Button
            className="btn btn-primary form-control-submit me-2"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
          {canSubmit ? (
            renderSubmitButton("Submit Quote")
          ) : (
            <Button className="btn btn-secondary form-control-submit" disabled>
              Submit Quote
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return {
    fields,
    handleSubmit,
    renderSubmitButton,
    canSubmit,
  };
};

export default useAddQuote;

import { useEffect, useState } from "react";
import { useApi, purchaseOrdersApi, useAuth } from "component-library";

const usePurchaseOrders = () => {
  const { user } = useAuth();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredPOs, setFilteredPOs] = useState([]);

  const { data, loading, error, request } = useApi(
    purchaseOrdersApi.getUserPurchaseOrders
  );

  const filterPOs = (q) => {
    setFilteredPOs(
      purchaseOrders.filter(
        (po) =>
          (!!po.supplier &&
            po.supplier.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
          po.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  useEffect(() => {
    if (user && user.id) request(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!data && data.purchase_orders) setPurchaseOrders(data.purchase_orders);
  }, [data]);

  useEffect(() => {
    setFilteredPOs(purchaseOrders);
  }, [purchaseOrders]);

  return {
    purchaseOrders,
    loading,
    error,
    filterPOs,
    filteredPOs,
  };
};

export default usePurchaseOrders;

import { SubmittalList as S } from "component-library";
import Status from "./Status";

const submittalList = () => [
  {
    name: "project_name",
    label: "Project",
    sort: "alpha",
    width: "16rem",
  },
  {
    name: "name",
    label: "Title",
    sort: "alpha",
    width: "18.5rem",
    isTitle: true,
  },
  {
    name: "priority_id",
    label: "Priority",
    sort: "numeric",
    render: S.Priority,
    width: "11.5rem",
  },
  {
    name: "updated_at",
    label: "Last Update",
    sort: "date",
    render: S.UpdateDate,
    width: "11.5rem",
  },
  {
    name: "due_date",
    label: "Due Date",
    sort: "date",
    render: S.DueDate,
    width: "11.5rem",
  },
  {
    name: "statusName",
    label: "Approval Status",
    classes: "overflow-visible",
    render: ({ item }) => <Status item={item} />,
    noLink: true,
    width: "11.5rem",
  },
];

export default submittalList;
import Bids from "./Bids";
import Provider from "./Provider";

const BidListing = () => {
  return (
    <Provider>
      <Bids />
    </Provider>
  );

}

export default BidListing;
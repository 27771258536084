import React from "react";
import styles from "./styles.module.scss";
import useInviteContact from "./useInviteContact";
import PropTypes from "prop-types";

const InviteContact = ({ addContact, close }) => {
  const { fields, handleSubmit } = useInviteContact(addContact, close);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>Add Contact</div>
        <div className={styles.modalBody}>
          <form
            className={`${styles.formContainer} p-2`}
            onSubmit={handleSubmit}
          >
            {fields}
          </form>
        </div>
      </div>
    </div>
  );
};

InviteContact.propTypes = {
  addContact: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default InviteContact;

import React from "react";
import Page from "../../_app/_layout/PublicPage";
import Banner from "../../_app/_module/Banner";
import ContactUsForm from "../../_forms/ContactUs";

const ContactUs = () => {

  return (
    <div className="landingWrapper">
      <Page isLanding={true}>
        <Banner copy="Contact Us" />
        <ContactUsForm />
      </Page>
    </div>
  );
};

export default ContactUs;

import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Status from "./../Status";

const AllProjects = () => {
  const projects = [
    {
      id: 1,
      name: "Project Name 1",
      number: "1111-22",
      status: "Active",
      sub_po: "123-21",
      quote: "4312-34",
    },
    {
      id: 2,
      name: "Project Name 2",
      number: "2222-33",
      status: "Precon",
      sub_po: "321-21",
      quote: "5321-23",
    },
  ];

  return (
    <div className={styles.allProjects}>
      <div className="d-flex align-items-center">
        <div className="xxl pe-3">All Projects</div>
        <Link className="btn btn-link med" to={"#"}>
          View Archive
        </Link>
      </div>
      <Table striped>
        <thead>
          <tr>
            <th
              style={{
                width: "11.5rem",
              }}
            >
              Project #
            </th>
            <th>Project Name</th>
            <th
              style={{
                width: "8.5rem",
              }}
            >
              Status
            </th>
            <th
              style={{
                width: "10rem",
              }}
            >
              SUB/PO #
            </th>
            <th
              style={{
                width: "15rem",
              }}
            >
              Quote #
            </th>
          </tr>
        </thead>
        <tbody>
          {!!projects.length &&
            projects.map((project, index) => (
              <tr key={index}>
                <td>{project.number}</td>
                <td className={styles.projectName}>{project.name}</td>
                <td>
                  <Status status={project.status} />
                </td>
                <td>{project.sub_po}</td>
                <td>{project.quote}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllProjects;

import React from "react";
import { ContactButton } from "../../../_app/_module/Buttons";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const CtaContainer = () => {

  return (
    <div className={styles.ctaContainer}>
      <div className={`${styles.ctaBlock} bg-primary lg`}>
        Have more questions?
        <div className="mt-3  font-weight-bold"><ContactButton /></div>
      </div>
      <div className={`${styles.ctaBlock} bg-navy text-white lg`}>
        Already a Vendor?
        <Link className="btn btn-tertiary mt-3 font-weight-bold" to="/">Vendor Login</Link>
      </div>
    </div>
  );
};

export default CtaContainer;

import "./App.scss";
import AppLoader from "./_app/Loader";
import { ProvideNotifications, ProvideAuth } from "component-library";

function App() {
  return (
    <div className="App">
      <ProvideAuth>
        <ProvideNotifications>
          <AppLoader />
        </ProvideNotifications>
      </ProvideAuth>
    </div>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { InteriorPage, useAuth } from "component-library";
import SidebarNavLinks from "../_module/SidebarNav";
import { ReactComponent as Tractics } from "../../assets/tractics-hdr-mobile.svg";

const PrivatePage = ({
  children,
  pageTitle,
  navTitle,
  subtitle,
  loading,
  error,
  navIcon,
  pageTitlePrimaryContent,
  pageTitleSecondaryContent,
  headerClass,
  pageSubtitlePrimaryContent,
  pageSubtitleSecondaryContent,
  fillScreen,
}) => {
  const { logout, user } = useAuth();

  return (
    <InteriorPage
      pageTitle={pageTitle}
      navTitle={navTitle}
      subtitle={subtitle}
      NavLinks={SidebarNavLinks}
      user={user}
      logout={logout}
      navHomeTitle={<Tractics width={100} />}
      loading={loading}
      error={error}
      navIcon={navIcon}
      pageTitlePrimaryContent={pageTitlePrimaryContent}
      pageTitleSecondaryContent={pageTitleSecondaryContent}
      pageSubtitlePrimaryContent={pageSubtitlePrimaryContent}
      pageSubtitleSecondaryContent={pageSubtitleSecondaryContent}
      fillScreen={fillScreen}
      headerClass={headerClass}
      hasWeather={false}
    >
      {(showMenu) => (
        <>{typeof children === "function" ? children(showMenu) : children}</>
      )}
    </InteriorPage>
  );
};

PrivatePage.propTypes = {
  children: PropTypes.any,
  pageTitle: PropTypes.string,
  navTitle: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.any,
  navIcon: PropTypes.any,
  pageTitlePrimaryContent: PropTypes.any,
  pageTitleSecondaryContent: PropTypes.any,
  pageSubtitlePrimaryContent: PropTypes.any,
  pageSubtitleSecondaryContent: PropTypes.any,
};

export default PrivatePage;

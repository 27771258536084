import { useState, useEffect } from "react";
import { useApi, projectApi, bidApi, useAuth } from "component-library";
import { DateTime } from "luxon";

const useBid = (id) => {
  const { user } = useAuth();
  const [bid, setBid] = useState(null);
  const [favorited, setFavorited] = useState(false);
  const [quote, setQuote] = useState(null);
  const [pastDue, setPastDue] = useState(true);

  const {
    request: bidRequest,
    loading: bidLoading,
    error: bidError,
    data: bidData,
  } = useApi(!!user ? bidApi.getBid : projectApi.getPublicBid);

  const { request: bidFavoriteRequest } = useApi(projectApi.favoriteProject);

  useEffect(() => {
    if (id) {
      if (!!user) {
        bidRequest(id, user.id);
      } else {
        bidRequest(id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  const favoriteBid = async () => {
    const { success, responseData } = await bidFavoriteRequest(id, user.id);
    if (success) {
      setFavorited(responseData.favorited);
    }
  };

  useEffect(() => {
    if (!!bidData && !!bidData.bid) {
      setBid(bidData.bid);
      setFavorited(bidData.bid.favorited || false);
      if (!!bidData.bid.quote) {
        setQuote(bidData.bid.quote);
      }
    }
    if (!!bidData && !!bidData.project) {
      setBid(bidData.project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bidData]);

  useEffect(() => {
    if (!!bid && !!bid.bid_date) {
      const dueDateString = bid.bid_date.substring(0, 10) + " " + bid.bid_time;
      const dueDate = DateTime.fromFormat(dueDateString, "yyyy-MM-dd HH:mm");

      const now = DateTime.local();
      if (now > dueDate) {
        setPastDue(true);
      } else {
        setPastDue(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  return {
    bid,
    bidLoading,
    bidError,
    favoriteBid,
    favorited,
    quote,
    setQuote,
    pastDue,
  };
};

export default useBid;

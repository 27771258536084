import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const Status = ({ status }) => {
  return (
    <span className={`${styles[status.toLowerCase()]} ${styles.pill}`}>
      {status}
    </span>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Status;

import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "../_pages/Dashboard";
import Landing from "../_pages/Landing";
import PrivateRoute from "./PrivateRoute";
import { ScrollToTop } from "component-library";
import ForgotPassword from "../_pages/_passwordManagement/ForgotPassword";
import ResetPassword from "../_pages/_passwordManagement/ResetPassword";
import HasAccount from "../_pages/HasAccount";
import ApplicationComplete from "../_pages/_application/ApplicationComplete";
import ApplicationApproved from "../_pages/_application/ApplicationApproved";
import Invited from "../_pages/Invited/Invited";
import ContactUs from "../_pages/ContactUs";
import VendorApplication from "../_pages/_application/Application";
import Bids from "../_pages/Bids";
import PurchaseOrders from "../_pages/PurchaseOrders";
import Bid from "../_pages/Bid";
import AccountSettings from "../_pages/_accountManagement/AccountSettings";
import NotificationSettings from "../_pages/_accountManagement/NotificationSettings";
import Quotes from "../_pages/Quotes";
import Quote from "../_pages/Quotes/Quote";
import PurchaseOrderRoutes from "../_pages/PurchaseOrders/PurchaseOrder/PurchaseOrderRoutes";
import Submittals from "../_pages/Submittals/SubmittalsList";
import Submittal from "../_pages/Submittals/Submittal";
import RFQInterested from "../_pages/RFQInterested";
import RFQNotInterested from "../_pages/RFQNotInterested";

const PortalRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="/login" element={<Landing />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/has-account" element={<HasAccount />} />
        <Route path="/application-complete" element={<ApplicationComplete />} />
        <Route path="/application-approved" element={<ApplicationApproved />} />
        <Route path="/invited" element={<Invited />} />
        <Route path="/vendor-application" element={<VendorApplication />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/rfq-interested" element={<RFQInterested />} />
        <Route path="/rfq-not-interested" element={<RFQNotInterested />} />
        <Route path="/purchase-orders/:poId/*" element={<PrivateRoute Component={<PurchaseOrderRoutes />} />} />
        <Route path="/purchase-orders" element={<PrivateRoute Component={<PurchaseOrders />} />} />
        <Route path="/quotes/:quoteId" element={<PrivateRoute Component={<Quote />} />} />
        <Route path="/quotes" element={<PrivateRoute Component={<Quotes />} />} />
        <Route path="/dashboard" element={<PrivateRoute Component={<Dashboard />} />} />
        <Route path="/active-bids" element={<PrivateRoute Component={<Bids />} />} />
        <Route path="/bid/:id" element={<PrivateRoute Component={<Bid />} />} />
        <Route path="/account-settings" element={<PrivateRoute Component={<AccountSettings />} />} />
        <Route path="/notification-settings" element={<PrivateRoute Component={<NotificationSettings />} />} />
        <Route path="/submittals/:submittalId" element={<PrivateRoute Component={<Submittal />} />} />
        <Route path="/submittals" element={<PrivateRoute Component={<Submittals />} />} />
      </Routes>
    </Router>
  );
};

export default PortalRoutes;

import React from "react";
import { quoteStatuses } from "component-library";
import { DateTime } from "luxon";

const Status = ({ quote }) => {
  const quoteStatus = quoteStatuses.find(qs => qs.id === quote.status);
  return (
    <>
      <div>
        <label className="q-label">Status</label>
        <div>{quoteStatus.name}</div>
      </div>
      <div className="q-updated">
        {`Updated at: ${
          !!quote &&
          !!quote.updated_at &&
          DateTime.fromISO(quote.updated_at).toFormat("L.dd.yy")
        }`}
      </div>
    </>
  );
}

export default Status;
import React from "react";
import styles from "./styles.module.scss";
import useContactUs from "./useContactUs";
import ContactInfo from "./ContactInfo";

function ContactUsForm() {
  const {
    fields,
    handleSubmit,
    success,
  } =
    useContactUs();


  return (
    <div className={`d-lg-flex justify-content-between align-items-start mt-4 container`}>
      <form className={`${styles.formContainer} p-2`} onSubmit={handleSubmit}>
        {!!success ? (
          <p className="text-center xl font-weight-bold mt-3">
            Thank you for reaching out. Submission received.
          </p>
        ) : (
          <>
            {fields}
          </>
        )}
      </form>
      <ContactInfo />
    </div>
  );
}

export default ContactUsForm;

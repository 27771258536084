import React from "react";
import { Quote as Q } from "component-library";
import BackToQuotesList from "./BackToQuotesList";
import PropTypes from "prop-types";
import Status from "./Status";

const QuoteDetails = ({ quote }) => {
  return (
    <>
      <div className="q-nav">
        <BackToQuotesList />
      </div>

      {!!quote && (
        <>
          <div className="q-secondary">
            <Q.MetaData quote={quote} />
          </div>
          <div className="q-contactSection">
            {!!quote.supplier && <Q.Contacts quote={quote} />}
            <div className="q-fileSection">
              <Status quote={quote} />
            </div>
          </div>
          <Q.Terms quote={quote} />
          <div className="q-section">
            <Q.LineItems quote={quote} />
          </div>
          <Q.Total total={quote.amount} />
          <Q.History history={quote.history} />
        </>
      )}
    </>
  );
};

QuoteDetails.propTypes = {
  requote: PropTypes.func,
  quote: PropTypes.object,
};

export default QuoteDetails;

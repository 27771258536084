import React from "react";
import Banner from "../../../_app/_module/Banner";
import Page from "../../../_app/_layout/PublicPage";
import { ResetPasswordForm, useAuth } from "component-library";

const ResetPassword = () => {
  const { resetPassword } = useAuth();

  return (
    <Page isLanding={true}>
      <Banner copy="Reset Password" />
      <ResetPasswordForm resetPassword={resetPassword} />
    </Page>
  );
};

export default ResetPassword;

import { useEffect, useState } from "react";
import { useApi, quoteApi, useAuth } from "component-library";

const useQuotes = () => {
  const { user } = useAuth();
  const [quotes, setQuotes] = useState([]);
  const [filteredQuotes, setFilteredQuotes] = useState([]);

  const { data, loading, error, request } = useApi(quoteApi.getUserQuotes);

  const filterQuotes = (q) => {
    setFilteredQuotes(
      quotes.filter(
        (po) =>
          (!!po.project.name &&
            po.project.name.toLowerCase().indexOf(q.toLowerCase()) > -1) ||
          po.id.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  };

  useEffect(() => {
    if (user && user.id) request(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!data && data.quotes) setQuotes(data.quotes);
  }, [data]);

  useEffect(() => {
    setFilteredQuotes(quotes);
  }, [quotes]);

  return {
    quotes,
    loading,
    error,
    filterQuotes,
    filteredQuotes,
  };
};

export default useQuotes;

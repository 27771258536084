import React from "react";
import useProvideBids from "./hooks/useProvideBids";
import Context from "./Context";

function ProvideBids({ children }) {
  const bids = useProvideBids();
  return <Context.Provider value={bids}>{children}</Context.Provider>;
}

export default ProvideBids;

import React from "react";
import { Briefcase } from "react-feather";
import Page from "../../../_app/_layout/PrivatePage";
import BackToPOsLink from "./BackToPOsLink";
import styles from "../styles.module.scss";
import PurchaseOrderDate from "./PurchaseOrderDate";
import Status from "./Status";
import Sum from "./Sum";
import { PO } from "component-library";
import PurchaseOrderControls from "./PurchaseOrderControls";
import usePurchaseOrder from "./usePurchaseOrder";
import SignPurchaseOrder from "./SignPurchaseOrder";
import { Quote as Q } from "component-library";

const PurchaseOrder = () => {
  const { error, purchaseOrder, getLoading } = usePurchaseOrder();

  return (
    <Page
      loading={getLoading}
      error={error}
      navTitle="Vendor Portal"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={`Purchase Order ${
        !!purchaseOrder ? `#${purchaseOrder.id}` : ""
      }`}
    >
      <div className="position-relative">
        <div className={styles.nav}>
          <BackToPOsLink />
          {!!purchaseOrder && (
            <PurchaseOrderControls purchaseOrder={purchaseOrder} />
          )}
        </div>
        {!!purchaseOrder && (
          <>
            <div className={styles.secondary}>
              <Status status={purchaseOrder.status} />
              <PurchaseOrderDate purchaseOrder={purchaseOrder} />
            </div>
            <div className={styles.contactSection}>
              {!!purchaseOrder.quote && !!purchaseOrder.quote.supplier && (
                <Q.Contacts quote={purchaseOrder.quote} />
              )}
              <PO.File
                purchaseOrder={purchaseOrder}
                url={`/purchase-orders/${purchaseOrder.id}/pdf`}
              />
            </div>
            <PO.DisplayScope purchaseOrder={purchaseOrder} />
            <Sum purchaseOrder={purchaseOrder} />
            <PO.Signatures purchaseOrder={purchaseOrder} />
            <div className={styles.section}>
              <PO.AttachedQuote quote={purchaseOrder.quote} quotePath={`/quotes/${purchaseOrder.quote.id}`} />
            </div>
            <SignPurchaseOrder />
          </>
        )}
      </div>
    </Page>
  );
};

export default PurchaseOrder;

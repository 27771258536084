import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { currency } from "component-library";
import { BeatLoader } from "react-spinners";

const LineItem = ({ item, updateItem, updateLoading, isUpdating, quoteItem }) => {
  const [lineItem, setLineItem] = useState(item);

  useEffect(() => {
    if (item) setLineItem(item);
  }, [item]);

  const setField = (name, value) => {
    setLineItem({...lineItem, [name]: value });
  };

  return (
    <>
      <td>
        {!!updateLoading && isUpdating && (
          <div className="loaderWrapper">
            <BeatLoader color={"#0071ce"} size={10} />
          </div>
        )}
        <input
          name="supplier_part_number"
          onChange={({ target: { value } }) =>
            setField("supplier_part_number", value)
          }
          className={styles.tableInput}
          value={lineItem.supplier_part_number}
          onBlur={() => updateItem(lineItem)}
        />
      </td>
      <td className={styles.nameCell}>
        {!!lineItem.material && lineItem.material.name}
      </td>
      <td>
        <span className={styles.quantity}>
          {!!quoteItem ? new Intl.NumberFormat("en-US").format(quoteItem.rfq_quantity) : "N/A"}
        </span>
        <span className={styles.unitOfMeasure}>
          {!!quoteItem && lineItem.rfq_quantity_uom}
        </span>
      </td>
      <td>
        <span>
          {new Intl.NumberFormat("en-US").format(lineItem.rfq_quantity)}
        </span>
        <span className={styles.unitOfMeasure}>
          {lineItem.rfq_quantity_uom}
        </span>
      </td>
      <td>
        <input
          name="base_price"
          onChange={({ target: { value } }) => setField("base_price", value)}
          className={styles.tableInput}
          value={lineItem.base_price}
          onBlur={() => updateItem(lineItem)}
        />
      </td>
      <td className="text-right">
        {currency(lineItem.rfq_quantity * lineItem.base_price)}
      </td>
    </>
  );
}

LineItem.propTypes = {
  item: PropTypes.object,
  updateItems: PropTypes.func,
};

export default LineItem;
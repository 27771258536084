import React, { useState, useEffect } from "react";
import Contacts from "./Contacts";
import styles from "./styles.module.scss";
import InviteContact from "../../../_forms/InviteContact";

const AddContact = () => {
  const [contacts, setContacts] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (!!contacts && !!contacts.length) {
      if (openModal) {
        setOpenModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  return (
    <div className="pt-2">
      <div className={styles.header}>Add Additional Contacts</div>
      <p className={styles.content}>
        Congratulations, you’re the first member of your company to sign up.
        Invite your co-workers to join you!
      </p>
      <Contacts contacts={contacts} openModal={() => setOpenModal(true)} />
      {openModal && (
        <InviteContact
          addContact={(contact) => {
            setContacts([...contacts, contact]);
          }}
          close={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default AddContact;

import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useApi, useFormControl, Joi, userApi } from "component-library";

const useHasAccount = (email) => {
  const [error, setError] = useState(false);
  const initialData = { email };
  const schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Account Email"),
  };

  // const {
  //   request: inviteRequest,
  //   data: inviteData,
  //   loading: inviteLoading,
  //   error: inviteError,
  // } = useApi(userApi.invite);

  const onSubmit = async (formData, setLoading) => {
    setError(false);
    // inviteRequest();
    setLoading(false);
  };

  const { handleSubmit, renderSubmitButton, renderTextField, data } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });
  
  // useEffect(() => {
  //   if (!!inviteData) {
  //     console.log(inviteData);
  //   }
  // }, [inviteData]);

  const fields = (
    <>
      {error && <Alert variant="danger">{error}</Alert>}
      <div className={`row d-flex justify-content-center xl font-weight-bold mb-3 text-gray2`}>
        Account Email
      </div>
      <div className="row">
        <div className="col mb-2 text-gray2">
          {renderTextField({
            name: "email",
            autoFocus: true,
            placeholder: "user-email@email.com",
            className: "primary-input font-weight-bold xxl"
          })}
        </div>
      </div>
    </>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    data,
  };
};

export default useHasAccount;

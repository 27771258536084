import React from "react";
import { BidSectionBasics, useAuth } from "component-library";
import BidLink from "./BidLink";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import AddQuote from "./AddQuote";

const BidPortalBasics = ({ bid, setQuote, quote, open, setOpen, pastDue }) => {
  const { user } = useAuth();

  return (
    <>
      <div className="d-flex justify-content-between align-items-center large-form-section">
        <div className="d-flex justify-content-between align-items-center">
          <BidSectionBasics bid={bid} isPortal={true} />
          {!!bid.specs_location && (
            <BidLink link={bid.specs_location} label="Spec" />
          )}
          {!!bid.plans_location && (
            <BidLink link={bid.plans_location} label="Plans" />
          )}
        </div>
        {!!user && !!!quote && !pastDue && (
          <div>
            <Button
              variant="orange"
              size="lg"
              onClick={() => {
                setOpen(true);
              }}
            >
              <span className="med">Submit a Quote</span>
            </Button>
          </div>
        )}
      </div>
      {!!user && open && (
        <AddQuote
          bid={bid}
          open={true}
          setOpen={setOpen}
          setQuote={setQuote}
          quote={quote}
        />
      )}
    </>
  );
};

BidPortalBasics.propTypes = {
  bid: PropTypes.object.isRequired,
  setQuote: PropTypes.func.isRequired,
  quote: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  pastDue: PropTypes.bool,
};

export default BidPortalBasics;

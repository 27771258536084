import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as Background } from "../../../assets/bg.svg";
import PropTypes from "prop-types";

function Banner({copy}) {
  return (
    <div className={`xxxl text-center ${styles.banner}`}>
      <Background className={styles.background} />
      <h1 className={`${styles.bannerText} xxxl mb-0`}>{copy}</h1>
    </div>
  );
}

Banner.propTypes = {
  copy: PropTypes.string,
};

export default Banner;

import React, { useEffect } from "react";
import Banner from "../_app/_module/Banner";
import { rfqsApi, useApi } from "component-library";
import PrivatePage from "../_app/_layout/PrivatePage";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RFQNotInterested = () => {
  const query = useQuery();
  const { loading, error, request } = useApi(rfqsApi.rfqNotInterested);

  useEffect(() => {
    if (!!query.get("vh")) request(query.get("vh"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="landingWrapper">
      <PrivatePage isLanding={true} loading={loading} error={error}>
        <Banner copy={"Thank you for your response"} />
      </PrivatePage>
    </div>
  );
};

export default RFQNotInterested;

import React from "react";
import styles from "./styles.module.scss";
import InfoList from "../../_app/_module/InfoList";
import useVendorApplication from "./useVendorApplication";

function VendorApplicationForm() {
  const {
    fields,
    handleSubmit,
    itemList
  } = useVendorApplication();

  return (
    <div className={`d-lg-flex justify-content-between align-items-start container`}>
      <div className={`${styles.sidebar} ${styles.mobileInfo}`}>
        <InfoList
          title="Why Become a Vendor?"
          primary={false}
          list={itemList}
        />
        <hr/>
      </div>
      <form onSubmit={handleSubmit} className={`${styles.form} vendor-application pt-3`}>
        {fields}
      </form>

      <div className={`${styles.sidebar} ${styles.desktopInfo} pt-3`}>
        <InfoList
          title="Why Become a Vendor?"
          primary={false}
          list={itemList}
        />
      </div>
    </div>
  );
}

export default VendorApplicationForm;

import React from "react";
import { Phone, Mail } from "react-feather";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const Contact = ({ contact }) => {
  return (
    <div className={styles.contact}>
      <div className={styles.status}>{contact.status}</div>
      <div className={styles.name}>
        {contact.first_name} {contact.last_name}
      </div>
      {!!contact.job_title && (
        <div className={styles.job}>{contact.job_title}</div>
      )}
      {!!contact.email && (
        <div>
          <div className={styles.email}>
            <Mail size={8} color={"#0071CE"} /> {contact.email}
          </div>
        </div>
      )}
      {!!contact.direct_phone && (
        <div>
          <div className={styles.phone}>
            <Phone size={8} color={"#0071CE"} /> {contact.direct_phone}
          </div>
        </div>
      )}
    </div>
  );
};

Contact.propTypes = {
  contact: PropTypes.object.isRequired,
};

export default Contact;

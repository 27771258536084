import React from "react";
import useProvidePurchaseOrder from "./useProvidePurchaseOrder";
import context from "./context";

function ProvidePurchaseOrder({ children }) {
  const purchaseOrder = useProvidePurchaseOrder();
  return (
    <context.Provider value={purchaseOrder}>
      {children}
    </context.Provider>
  );
}

export default ProvidePurchaseOrder;

import React from "react";
import Page from "../../../_app/_layout/PublicPage";
import VendorApplication from "../../../_forms/VendorApplication";

const Application = () => {
  return (
    <div className="landingWrapper">
      <Page isLanding={true} title="New Vendor Application">
        <VendorApplication />
      </Page>
    </div>
  );
};

export default Application;

import React from "react";
import Page from "../../../_app/_layout/PrivatePage";
import useQuote from "./useQuote";
import { Tag } from "react-feather";
import Requote from "./Requote";
import { RiseLoader } from "react-spinners";
import QuoteDetails from "./QuoteDetails";

const Quote = () => {
  const {
    error,
    quote,
    setQuote,
    getLoading,
    setLoading,
    setError,
    createRequote,
    loading,
  } = useQuote();

  const pageTitle = `${!!quote && quote.status === 3 ? "Requote" : "Quote"} ${
    !!quote ? quote.id : ""
  }`;

  return (
    <Page
      loading={getLoading}
      error={error}
      navTitle={"Vendor Portal"}
      navIcon={<Tag color="#fff" size={14} />}
      pageTitle={pageTitle}
      className="text-gray2"
    >
      <div className="position-relative">
        {!!loading && (
          <div className="loaderWrapper">
            <RiseLoader color={"#0071ce"} />
          </div>
        )}
        {!!quote && (
          <>
            {quote.status === 3 && !!quote.requote ? (
              <Requote
                quote={quote}
                setQuote={setQuote}
                setLoading={setLoading}
                setError={setError}
              />
            ) : (
              <QuoteDetails quote={quote} requote={createRequote} />
            )}
          </>
        )}
      </div>
    </Page>
  );
};

export default Quote;

import React from "react";
import styles from "../styles.module.scss";
import usePurchaseOrder from "./usePurchaseOrder";
import { BeatLoader } from "react-spinners";

const PurchaseOrderControls = ({ purchaseOrder }) => {
  const { setSignatureModalOpen } = usePurchaseOrder();
  return (
    <>
      <div className={styles.controls}>
        {(purchaseOrder.status === 1 || purchaseOrder.status === 2) && (
          <button
            className="btn btn-tertiary"
            onClick={() => setSignatureModalOpen(true)}
            disabled={purchaseOrder.status === 2}
          >
            {purchaseOrder.status === 2 ? (
              <>
                <BeatLoader color={"#fff"} size={10} />
              </>
            ) : (
              "Sign Purchase Order"
            )}
          </button>
        )}
        {purchaseOrder.status === 5 && (
          <button className="btn btn-tertiary" onClick={() => {}}>
            Submit Invoice
          </button>
        )}
      </div>
    </>
  );
};

export default PurchaseOrderControls;

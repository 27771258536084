import React from "react";
import styles from "../styles.module.scss";
import { PO } from "component-library";

const Status = ({ status }) => {
  return (
    <div className="d-flex align-items-center me-4 pe-2">
      <label className={styles.dateLabel}>Status</label>
      <span>{PO.statuses[status]}</span>
    </div>
  );
};

export default Status;

import React from "react";
import { Folder } from "react-feather";
import { StyledDatePicker } from "component-library";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const QuoteSideBar = ({ quote }) => {
  return (
    <div className={`col-md-3 text-right ${styles.sidebar}`}>
      <div className={styles.yourQuote}>
        <span className={styles.quotesTitle}>Your Quote</span>
        <div className={styles.quotes}>
          {!!quote.file && (
            <a
              href={quote.file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.quoteFile}>
                <Folder size={20} color="#D3D3D3" />
                <span className={styles.quotesText}>{quote.file.file_name}</span>
              </div>
            </a>
          )}
        </div>
      </div>
      <div className="py-2">
        <div className={styles.sidebarTitle}>Quote Amount</div>
        <input
          type="text"
          className={styles.sidebarInput}
          value={`$${quote.amount}`}
          disabled
        />
      </div>
      <div className="py-2">
        <div className={styles.sidebarTitle}>Quote Date</div>
        <StyledDatePicker value={quote.quote_date} disabled={true} />
      </div>
      <div className="py-2">
        <div className={styles.sidebarTitle}>Quote Expiration Date</div>
        <StyledDatePicker value={quote.quote_expiration_date} disabled={true} />
      </div>
    </div>
  );
};

QuoteSideBar.propTypes = {
  quote: PropTypes.object.isRequired,
};

export default QuoteSideBar;

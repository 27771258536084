import Status from "./Status";
import ProjectStatus from "./ProjectStatus";

const quoteListConfig = [
  {
    name: "project_name",
    label: "Project Name",
    sort: "alpha",
    width: "55%",
    isTitle: true,
  },
  {
    name: "project_status",
    label: "Project Status",
    sort: "alpha",
    width: "15%",
    render: ProjectStatus,
  },
  {
    name: "status",
    label: "Quote Status",
    sort: "numeric",
    width: "15%",
    render: Status
  },
  {
    name: "amount",
    label: "Bid Amnt",
    sort: "numeric",
    width: "15%",
    isCurrency: true,
  },
];

export default quoteListConfig;

import { useEffect, useState } from "react";
import { useApi, purchaseOrdersApi } from "component-library";
import { useParams } from "react-router-dom";
import useInterval from "react-useinterval";

const useProvidePurchaseOrder = () => {
  const { poId } = useParams();

  const [purchaseOrder, setPurchaseOrder] = useState();
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(false);

  const {
    data,
    loading: getLoading,
    error,
    request,
  } = useApi(purchaseOrdersApi.getPurchaseOrder);

  const {
    data: statusData,
    request: statusRequest,
  } = useApi(purchaseOrdersApi.getStatus);

  useEffect(() => {
    if (poId) request(poId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poId]);

  useEffect(() => {
    if (!!data && !!data.purchaseOrder) setPurchaseOrder(data.purchaseOrder);
  }, [data]);

  useEffect(() => {
    if (!!purchaseOrder) {
      if (purchaseOrder.status === 2 || purchaseOrder.status === 4) {
        setCheckingStatus(true);
      } else {
        setCheckingStatus(false);
      }
    }
  }, [purchaseOrder]);

  useEffect(() => {
    if (statusData && statusData.status)
      setPurchaseOrder({ ...purchaseOrder, status: statusData.status[0] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusData])

  const checkStatus = () => {
    statusRequest(purchaseOrder.id);
  };

  useInterval(checkStatus, checkingStatus ? 2000 : null);

  return {
    purchaseOrder,
    error,
    getLoading,
    signatureModalOpen,
    setSignatureModalOpen,
    setCheckingStatus,
  };
};

export default useProvidePurchaseOrder;

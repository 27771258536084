import React from "react";
import { Briefcase } from "react-feather";
import Page from "../../_app/_layout/PrivatePage";
import usePurchaseOrders from "./usePurchaseOrders";
import config from "./poListConfig";
import { FilterSearch, SortableTable } from "component-library";

const PurchaseOrders = () => {
  const {
    filteredPOs,
    loading,
    error,
    filterPOs,
  } = usePurchaseOrders();

  return (
    <Page
      loading={loading}
      error={error}
      navTitle="Vendor Portal"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Purchase Orders"
    >
      <div className="d-flex justify-content-end align-items-center mb-3">
        <FilterSearch
          searchFunction={filterPOs}
          inline
          placeholder="Search Purchase Orders"
          style={{ minWidth: 300 }}
        />
      </div>
      <SortableTable
        items={filteredPOs.map((po) => ({
          ...po,
          remaining: parseFloat(po.sum) - parseFloat(po.invoiced_amount),
        }))}
        config={config}
        fallbackSort="supplier_name"
        url={(item) => `/purchase-orders/${item.id}`}
        sticky
      />
    </Page>
  );
};

export default PurchaseOrders;

import { useState, useEffect } from "react";
import { useApi, bidApi, projectApi, useAuth } from "component-library";

const useProvideBids = () => {
  const [allBids, setAllBids] = useState([]);
  const [bids, setBids] = useState([]);
  const [bidsFilter, setBidsFilter] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [filter, setFilter] = useState(null);
  const { user } = useAuth();

  const {
    request: getBidsRequest,
    loading: getBidsLoading,
    error: getBidsError,
    data: getBidsData,
  } = useApi(bidApi.getBids);

  const {
    request: companyBidsRequest,
    loading: companyBidsLoading,
    error: companyBidsError,
    data: companyBidsData,
  } = useApi(projectApi.getCompanyBids);

  useEffect(() => {
    getBidsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!getBidsData && !!getBidsData.projects) {
      setBids(getBidsData.projects);
      setAllBids(getBidsData.projects);
      const filterOptions = getBidsData?.projects?.map((project) => {
        return { value: project?.category?.id, label: project?.category?.name };
      });
      setFilterOptions(filterOptions);
    }
  }, [getBidsData]);

  const favoriteBid = (bidId) => {
    // todo
  };

  useEffect(() => {
    if (!!filter) {
      const filteredBids = allBids.filter((bid) => {
        return bid.category.id === filter.value;
      });
      setBids(filteredBids);
    } else {
      setBids(allBids);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return {
    bids,
    bidsFilter,
    setBidsFilter,
    getBidsLoading,
    getBidsError,
    favoriteBid,
    filterOptions,
    setFilter,
    filter,
  };
};

export default useProvideBids;

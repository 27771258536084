import React from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "component-library";
import {
  AlertTriangle,
  Briefcase,
  Calendar,
  DollarSign,
  FileText,
  UserCheck,
  UserPlus,
  Users,
} from "react-feather";
import { Link } from "react-router-dom";

function SidebarNav() {
  const { user } = useAuth();
  return (
    <>
      {!!user ? (
        <>
          {/* <NavLink to="/active-projects" className="siteNavItem">
            <Briefcase size="14" color="#fff" />
            Active Projects
          </NavLink> */}
          <NavLink to="/active-bids" className="siteNavItem">
            <Users size="14" color="#fff" />
            Active Bids
          </NavLink>
          <NavLink to="/quotes" className="siteNavItem">
            <UserCheck size="14" color="#fff" />
            Quotes
          </NavLink>
          {/* <NavLink to="/sub-contracts" className="siteNavItem">
            <FileText size="14" color="#fff" />
            Sub Contracts
          </NavLink> */}
          <NavLink to="/submittals" className="siteNavItem">
            <DollarSign size="14" color="#fff" />
            Submittals
          </NavLink>
          <NavLink to="/purchase-orders" className="siteNavItem">
            <Calendar size="14" color="#fff" />
            Purchase Orders
          </NavLink>
          {/* <NavLink to="/pay-apps" className="siteNavItem">
            <AlertTriangle size="14" color="#fff" />
            Pay Apps
          </NavLink> */}
          {/* <NavLink to="/change-management" className="siteNavItem">
            <UserPlus size="14" color="#fff" />
            Change Management
          </NavLink> */}
          {/* <NavLink to="/company-info" className="siteNavItem">
            <FileText size="14" color="#fff" />
            Company Info
          </NavLink> */}
        </>
      ) : (
        <div className="p-3 text-light">
          <Link to="/login" className="pe-1 blue-hover">
            Login
          </Link>
          or
          <Link to="/vendor-application" className="ps-1 blue-hover">
            Apply for a vendor account
          </Link>
        </div>
      )}
    </>
  );
}

export default SidebarNav;

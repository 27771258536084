import React from 'react';
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

const Public = ({title}) => {
  return (
    <header className={`${styles.public} d-flex`}>
      <Link to="/dashboard" className={`d-inline-flex align-items-center navbar-brand ${styles.navBrand}`}>
        <Logo />
        <h1 className="gray02 xl mb-0 ms-3 text-gray2">{!!title ? title : "Vendor Portal"}</h1>
      </Link>
    </header>
  );
}

export default Public;
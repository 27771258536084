import React from "react";
import styles from "./styles.module.scss";
import Proptypes from "prop-types";
import { Avatar } from "component-library";

const Notification = ({ user, submittalName, action, file }) => {
  const userName = `${!!user.first_name && user.first_name} ${!!user.last_name && user.last_name}`;

  return (
    <div className={styles.update}>
      <Avatar size="full" user={user} hasColor />
      <div className={styles.content}>
        <span className={styles.userName}>{userName}</span>
        {` ${action} `}
        {!!file && (
          <>
            <a href={file.url}>{file.name}</a>
            {" to "}
          </>
        )}
        {!!submittalName && <span className={styles.submittalName}>{submittalName}</span>}
      </div>
    </div>
  );
};

Notification.propTypes = {
  user: Proptypes.object,
  submittalName: Proptypes.string,
  action: Proptypes.string,
  file: Proptypes.object,
};

export default Notification;

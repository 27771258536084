import React from "react";
import { Star } from "react-feather";

const FollowBid = ({ favorited, favoriteBid  }) => {
  return (
    <div className="d-flex align-items-center ms-3" onClick={favoriteBid}>
      {favorited ? (
        <Star color="#F0C808" fill="#F0C808" size={20} className="me-2" />
      ) : (
        <Star color="#D3D3D3" size={20} className="me-2" />
      )}
      <div className="ps-1">Follow Bid</div>
    </div>
  );
};

export default FollowBid;

import React from "react";
import Controls from "./Controls";
import LineItems from "./LineItems";
import OriginalQuote from "./OriginalQuote";
import PropTypes from "prop-types";
import { Quote as Q, useAuth } from "component-library";
import useRequote from "./useRequote";

const Requote = ({ quote, setQuote, setLoading, setError }) => {
  const { user } = useAuth();
  const { submitRequote, updateTerms, updateRequote, submitData } = useRequote(
    quote,
    setQuote,
    setLoading,
    setError
  );

  return (
    <>
      <Controls
        quote={quote}
        submitRequote={submitRequote}
        submitData={submitData}
      />
      <Q.EditMetaData quote={quote} updateQuote={updateRequote} />
      <Q.File quote={quote} setQuote={setQuote} user={user} />
      <div className="q-section pt-3 mt-4">
        <Q.EditTerms quote={quote} updateTerms={updateTerms} />
      </div>
      {!!quote.requote &&
        !!quote.requote.line_items &&
        !!quote.requote.line_items.length && (
          <LineItems setQuote={setQuote} quote={quote} />
        )}
      <OriginalQuote quote={quote} />
    </>
  );
};

Requote.propTypes = {
  quote: PropTypes.object,
  setQuote: PropTypes.func,
  updateRequoteStatus: PropTypes.func,
  updateTerms: PropTypes.func,
};

export default Requote;

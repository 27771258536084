import React, { useState } from "react";
import { MapPin, AlertTriangle } from "react-feather";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";

const Todo = ({ item, initalOpen = false, flag = false, fileRequired = false, dateString }) => {
  const [isOpen, setIsOpen] = useState(initalOpen);

  return (
    <div className={`row mb-3 mx-1 ${isOpen ? styles.open : ""}`}>
      <div className={`col-12 d-flex p-2 ${styles.header}`} onClick={() => setIsOpen(!isOpen)}>
        <div className="font-weight-bold me-3 text-nowrap align-self-center">
          {flag ? <AlertTriangle color="#0071ce" size={16} /> : <>{dateString}</>}
        </div>
        <div>{item.name}</div>
      </div>
      {isOpen && (
        <div className={`col-12  p-2 ${styles.description}`}>
          <i>{item.description}</i>
          {!!item.address && (
            <div>
              <a href="/#">
                <MapPin color="#0071ce" size={16} className="me-2" />
                {item.address}
              </a>
            </div>
          )}
          {fileRequired && (
            <div className="my-1">
              <Button variant="orange" size="med" className="mt-2">
                Upload File
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Todo;

import React, { useState, useEffect } from "react";
import { DollarSign, Archive, Eye } from "react-feather";
import Page from "../../../_app/_layout/PrivatePage";
import useSubmittalsList from "./useSubmittalsList";
import {
  SortableTable,
  useSubmittalFilters,
  submittalConfig,
  HeaderButton,
  useAuth,
} from "component-library";
import listConfig from "./listConfig";
import { ReactComponent as BBall } from "../../../assets/bball.svg";

const SubmittalsList = () => {
  const { config } = useAuth();
  const { loading, error, submittals } = useSubmittalsList();
  const [sortedSubmittals, setSortedSubmittals] = useState();
  const [viewingArchive, setViewingArchive] = useState(false);
  const isPortal = true;

  const { filteredSubmittals, RenderFilters } = useSubmittalFilters(
    submittals,
    config,
    isPortal
  );

  useEffect(() => {
    if (!!filteredSubmittals) {
      let displaySubmittals = filteredSubmittals.filter(
        (s) => s.status_id !== 9
      );
      if (!!viewingArchive) {
        displaySubmittals = filteredSubmittals.filter((s) => s.status_id === 9);
      }
      setSortedSubmittals({
        Boom: displaySubmittals.filter((e) => e.bic_id === 1),
        Source: displaySubmittals.filter((e) => e.bic_id === 3),
        "Project Engineer": displaySubmittals.filter((e) => e.bic_id === 2),
      });
    }
  }, [filteredSubmittals, viewingArchive]);

  return (
    <Page
      loading={loading}
      error={error}
      navTitle="Vendor Portal"
      navIcon={<DollarSign color="#fff" size={14} />}
      pageTitleSecondaryContent={() => (
        <HeaderButton
          onClick={() => {
            setViewingArchive(!viewingArchive);
          }}
          className="d-flex align-items-center btn-link btn text-decoration-none"
        >
          {!!viewingArchive ? (
            <>
              <Eye size="14" />
              View Active
            </>
          ) : (
            <>
              <Archive className="me-2" size="14" />
              View Archive
            </>
          )}
        </HeaderButton>
      )}
      pageTitle="Submittals"
    >
      {!!submittals && <RenderFilters />}
      {!!sortedSubmittals && (
        <>
          {Object.keys(sortedSubmittals).map((key) => (
            <div className="row mb-5" key={key}>
              <div className="col">
                <section className="sortable-table">
                  <header className="d-flex justify-content-between align-items-end pb-1 border-bottom border-gray5">
                    <hgroup className="d-lg-flex align-items-end">
                      <h4
                        className={`d-flex align-items-center me-lg-3 lg font-weight-bold`}
                      >
                        <BBall className="me-2" />
                        {key !== "Boom" ? <>{key}</> : <>Boomerang</>}
                      </h4>
                      <h6
                        className={`mx-lg-3 sm text-gray3`}
                      >{`${sortedSubmittals[key].length} Total`}</h6>
                    </hgroup>
                  </header>
                  <SortableTable
                    items={sortedSubmittals[key].map((s) => ({
                      ...s,
                      statusName: config.submittal_statuses.find(
                        (status) => status.id === s.status_id
                      ),
                      statusColor: submittalConfig.statusColors[s.status_id],
                      project_name: s.project_name.name,
                    }))}
                    config={listConfig()}
                    fallbackSort="description"
                    url={(item) => `/submittals/${item.id}`}
                  />
                </section>
              </div>
            </div>
          ))}
        </>
      )}
    </Page>
  );
};

export default SubmittalsList;

import React, { useEffect, useState } from "react";
import Page from "../../_app/_layout/PrivatePage";
import styles from "./styles.module.scss";
import useBid from "./hooks/useBid";
import { useParams } from "react-router-dom";
import {
  BidSectionMilestones,
  BidSectionProjectInfo,
  BidSectionDocuments,
  BidSectionAddenda,
  useAuth,
} from "component-library";
import BidPortalBasics from "./BidPortalBasics";
import FollowBid from "./FollowBid";
import QuoteSideBar from "./QuoteSideBar";
import RequoteLink from "./RequoteLink";
import { Tag } from "react-feather";
import RFQResponse from "./RFQResponse";

const Bid = () => {
  const { id } = useParams();
  const {
    bid,
    bidLoading,
    bidError,
    favoriteBid,
    favorited,
    quote,
    setQuote,
    pastDue,
  } = useBid(id);
  const [open, setOpen] = useState(false);

  const { config, userCan, user } = useAuth();
  const [CFG, setCFG] = useState();

  const Follow = () => (
    <>{user && <FollowBid favorited={favorited} favoriteBid={favoriteBid} />}</>
  );
  const Requote = () => (
    <>{user && !pastDue && <RequoteLink quote={quote} setOpen={setOpen} />}</>
  );

  useEffect(() => {
    if (!!bid) {
      setCFG(
        !!user
          ? config
          : {
              maintenance_period_config: bid.maintenance_period_config,
              total_days_config: bid.total_days_config,
              working_day_type_config: bid.working_day_type_config,
              contract_turnaround_time_config:
                bid.contract_turnaround_time_config,
              disincentive_config: bid.disincentive_config,
              liquidated_damages_config: bid.liquidated_damages_config,
              incentive_config: bid.incentive_config,
            }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bid]);

  return (
    <Page
      className={styles.wrapper}
      pageTitle={!!bid ? bid.name : ""}
      subtitle={`#${id}`}
      pageTitlePrimaryContent={Follow}
      pageTitleSecondaryContent={Requote}
      loading={bidLoading}
      error={bidError}
      navTitle="Boomerang Vendor Portal"
      navIcon={<Tag color="#fff" size={14} />}
    >
      <>
        <div className="row">
          <div className={!!quote ? "col-md-9" : "col-12"}>
            {!!bid && !!CFG && (
              <>
                <RFQResponse />
                <BidPortalBasics
                  bid={bid}
                  setQuote={setQuote}
                  quote={quote}
                  open={open}
                  setOpen={setOpen}
                  pastDue={pastDue}
                />
                {!!bid && !!bid.addendas && !!bid.addendas.length && (
                  <BidSectionAddenda
                    bid={bid}
                    userCan={!!userCan ? userCan : () => false}
                    isPortal={true}
                  />
                )}
                <BidSectionProjectInfo
                  project={bid}
                  config={CFG}
                  isPortal={true}
                />
                <BidSectionDocuments
                  bid={bid}
                  userCan={!!userCan ? userCan : () => false}
                  loggedIn={!!user}
                  isPortal={true}
                />
                {!!bid && !!bid.milestones.length && (
                  <BidSectionMilestones
                    milestones={bid.milestones}
                    config={CFG}
                    isPortal={true}
                  />
                )}
              </>
            )}
          </div>
          {!!quote && <QuoteSideBar quote={quote} />}
        </div>
      </>
    </Page>
  );
};

export default Bid;

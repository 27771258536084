import React from "react";
import { ContactButton } from "../../../_app/_module/Buttons";

function Footer() {

  return (
    <div className="bg-navy pt-4 pb-4 text-center">
      <h2 className="lg text-white font-weight-bold">Have more questions?</h2>
      <ContactButton />
    </div>
  );
}

export default Footer;
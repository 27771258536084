import { useEffect, useState } from "react";
import { useApi, quoteApi } from "component-library";

const useUpdateLineItem = (quote, setQuote) => {
  const [lineUpdating, setLineUpdating] = useState();
  const {
    data: updateLineData,
    loading: updateLineLoading,
    error: updateLineError,
    request: updateLineRequest,
  } = useApi(quoteApi.updateLineItem);

  const setUpdatedLineItems = (updatedLineItem) => {
    setQuote({
      ...quote,
      requote: {
        ...quote.requote,
        line_items: quote.requote.line_items.map((li) =>
          li.id === updatedLineItem.id ? updatedLineItem : li
        ),
      },
    });
  };

  const updateLineItem = (lineItem) => {
    setLineUpdating(lineItem.id);
    updateLineRequest(lineItem);
  };

  useEffect(() => {
    if (!!updateLineData && updateLineData.line_item) {
      setLineUpdating();
      setUpdatedLineItems(updateLineData.line_item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLineData]);

  return {
    updateLineItem,
    updateLineLoading,
    updateLineError,
    lineUpdating
  };
};

export default useUpdateLineItem;

import React from "react";
import { Modal } from "react-bootstrap";
import useAddQuote from "./hooks/useAddQuote";
import PropTypes from "prop-types";

const AddQuote = ({ bid, open, setOpen, setQuote, quote }) => {
  const { fields, handleSubmit } = useAddQuote({
    bid,
    setOpen,
    setQuote,
    quote,
  });
  return (
    <Modal show={open} onHide={() => setOpen(false)} size="lg" centered animation={false}>
      <Modal.Header closeButton>
        <h4>Submit a Quote</h4>
      </Modal.Header>
      <Modal.Body>
        <form className="p-2" onSubmit={handleSubmit}>
          {fields}
        </form>
      </Modal.Body>
    </Modal>
  );
};

AddQuote.propTypes = {
  bid: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setQuote: PropTypes.func.isRequired,
}

export default AddQuote;

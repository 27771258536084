import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const NoActivity = ({ activeBidCount = 0 }) => {
  return (
    <div className={styles.messageBlock}>
      <div className={styles.message}>
        <div className="xxxl">You don't have any activity yet!</div>
        <div className="lg py-3">There are {activeBidCount} active bids waiting for quotes</div>
        <Link to="/active-bids">
          <Button variant="orange">Review Bids</Button>
        </Link>
      </div>
    </div>
  );
};

export default NoActivity;

import React from "react";
import { SortableTable } from "component-library";
import Select from "react-select";
import Page from "../../_app/_layout/PrivatePage";
import styles from "./styles.module.scss";
import config from "./Table/Config";
import useBids from "./hooks/useBids";

const Bids = () => {
  const { bids, filter, setFilter, filterOptions, getBidsLoading, getBidsError } =
    useBids();
  return (
    <Page className={styles.wrapper} title="Active Bids" loading={getBidsLoading} error={getBidsError}>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <div className="xxl">Active Bids</div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="lg me-2">Filter Bids</div>
              <Select
                options={filterOptions}
                value={filter}
                onChange={(e) => setFilter(e)}
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    minWidth: "20rem",
                  }),
                }}
              />
            </div>
          </div>
          <SortableTable items={bids} config={config} fallbackSort={"id"} url={(item) => `/bid/${item.id}`} />
        </div>
      </div>
    </Page>
  );
};

export default Bids;

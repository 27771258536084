import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useFormControl, Joi, useAuth } from "component-library";

const useSetPassword = (token, email) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { resetPassword } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const initialData = { password: "", password_confirmation: "" };
  const schema = {
    password: Joi.string().min(8).required().label("Password"),
    password_confirmation: Joi.ref("password"),
  };

  const onSubmit = async (formData, setLoading) => {
    if (!!token && !!email) {
      const data = { ...formData, token, email };
      setError(false);
      try {
        const successful = await resetPassword(data);
        if (successful) {
          setSuccess(true);
        }
      } catch (message) {
        setLoading(false);
        setError(message);
      }
    }
    setLoading(false);
    setError("Invalid email and/or token");
  };

  const { handleSubmit, renderSubmitButton, renderTextField, data } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });

  const fields = (
    <div class="container">
      {!!error && (
        <Alert onClick={() => setError(false)} dismissible variant="danger">
          {error}
        </Alert>
      )}
      <div>
        <h2 className="xl text-center font-weight-bold text-gray2">
          Account Email
        </h2>
        <div className="primary-input d-flex justify-content-center align-items-center mb-4 md font-weight-bold text-gray3 xxxl px-2">
          user-email@email.com
        </div>
        <div className="xl text-center font-weight-bold text-gray2">
          Create Password
        </div>
        <p className="max-text-wrapper mx-auto text-gray2 text-center">
          password requirements
        </p>
      </div>
      <div className="row mb-2">
        <div className="col">
          {renderTextField({
            name: "password",
            autoComplete: "current-password",
            placeholder: "Password",
            type: "password",
            showPassword: showPassword,
            setShowPassword: setShowPassword,
            hasIcon: true,
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "password_confirmation",
            autoComplete: "current-password",
            placeholder: "Re-enter Password",
            type: "password",
            showPassword: showPasswordConfirmation,
            setShowPassword: setShowPasswordConfirmation,
            hasIcon: true,
          })}
        </div>
      </div>
    </div>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    success,
  };
};

export default useSetPassword;

import React from "react";
import styles from "./styles.module.scss";
import { currency } from "component-library";
import PropTypes from "prop-types";

const OriginalQuote = ({quote}) => {
  return (
    <div className="q-section pt-4">
      <div className={`${styles.history}  text-gray2`}>
        <h2 className="med font-weight-bold">Original Quote</h2>
        <div className={styles.historyItem}>
          <div className="flex-grow-1 me-5 font-weight-bold">Quote #</div>
          <div className="d-flex justify-content-between">
            <div className="me-5">45 Line items</div>
            <div className="font-weight-bold ms-5">{currency(2500)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

OriginalQuote.propTypes = {
  quote: PropTypes.object,
};

export default OriginalQuote;
import React from "react";
import { Link } from "react-router-dom";
import PublicPage from "../../_app/_layout/PublicPage";
import LoginForm from "./LoginForm";
import Footer from "./Footer";
import InfoList from "../../_app/_module/InfoList";
import styles from "./styles.module.scss";
import { Bell, TrendingUp, Calendar, Repeat, Lock } from "react-feather";

function Login() {
  const itemList = [
    {
      text: "Alii autem, quibus ego assentior, cum a natura incorrupte atque corrupti, quos tu tam inportuno tamque crudeli.",
      icon: <Bell size="16" />,
    },
    {
      text: "Si sine causa, nollem me tamen laudandis maioribus meis corrupisti nec in voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <TrendingUp size="16" />,
    },
    {
      text: "Sed ut earum rerum hic tenetur a se ipsam voluptatem, quia dolor sit, a sapiente delectus.",
      icon: <Calendar size="16" />,
    },
    {
      text: "Si sine causa, nollem me tamen laudandis maioribus meis corrupisti nec in voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <Repeat size="16" />,
    },
    {
      text: "Sed ut earum rerum hic tenetur a se ipsam voluptatem, quia dolor sit, a sapiente delectus.",
      icon: <Lock size="16" />,
    },
  ];

  return (
    <div className="landingWrapper">
      <PublicPage isLanding={true}>
        <div className="d-lg-flex flex-row flex-grow-1">
          <div
            className={`position-relative d-flex justify-content-center flex-fill bg-primary align-items-center overflow-hidden ${styles.halfColumn}`}
          >
            <LoginForm />
          </div>
          <div
            className={`d-flex flex-column flex-fill bg-navy align-items-center justify-content-center pb-2 ${styles.halfColumn}`}
          >
            <div className="row d-flex justify-content-center text-white xl font-weight-bold">Not a Vendor yet?</div>
            <div className="row d-flex justify-content-center text-white mb-2">Click here to get started</div>
            <Link className={`btn btn-tertiary text-white`} to={`/vendor-application`}>
              Apply Here
            </Link>
          </div>
        </div>
        <InfoList title="Why become a vendor?" hasLogo={true} list={itemList} />
        <Footer />
      </PublicPage>
    </div>
  );
}

export default Login;

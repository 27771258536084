import React from "react";
import { ContactButton } from "../../_app/_module/Buttons";
import styles from "./styles.module.scss";

function Contact() {

  return (
    <div className={`${styles.contactBlock} px-5 py-4 text-center`}>
      <h2 className="font-weight-bold xl text-center text-gray2">
        Need Help?
      </h2>
      <p className="text-center text-gray2">
        Alii autem, quibus ego assentior, cum a natura incorrupte atque corrupti, quos tu tam inportuno tamque crudeli
      </p>
      <ContactButton />
    </div>
  );
}

export default Contact;

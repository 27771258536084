import { useEffect, useState } from "react";
import { useApi, submittalsApi, useAuth } from "component-library";

const useSubmittalsList = () => {
  const { user } = useAuth();
  const [submittals, setSubmittals] = useState([]);
  const [error, setError] = useState();

  const {
    data,
    loading,
    error: requestError,
    request: submittalsRequest,
  } = useApi(submittalsApi.submittalsByUser);

  useEffect(() => {
    if (user && user.id) {
      submittalsRequest(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!data && !!data.submittals) {
      setSubmittals(data.submittals);
    }
  }, [data]);

  useEffect(() => {
    if (!!requestError) {
      setError(requestError);
    } else {
      setError();
    }
  }, [requestError]);

  return {
    submittals,
    loading,
    error,
  };
};

export default useSubmittalsList;

import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const Controls = ({ quote, submitRequote, submitData }) => {
  return (
    <>
      {!!submitData && <Navigate to={`/quotes/${quote.requote.id}`} />}
      <div className={styles.controls}>
        <Button className={`btn-tertiary font-weight-bold link ${styles.link}`} onClick={submitRequote}>
          Send Requote
        </Button>
      </div>
    </>
  );
};

Controls.propTypes = {
  updateRequoteStatus: PropTypes.func,
};

export default Controls;

import React from "react";
import { Briefcase } from "react-feather";
import Page from "../../_app/_layout/PrivatePage";
import useQuotes from "./useQuotes";
import config from "./quoteListConfig";
import { FilterSearch, SortableTable } from "component-library";

const PurchaseOrders = () => {
  const { filteredQuotes, loading, error, filterQuotes } = useQuotes();

  const getAdjustedStatus = (quote) => {
    let status = quote.status;
    if (quote.status === 2) status = 1;
    if (!!quote.used && quote.status !== 3) status = 5;
    return status;
  };

  return (
    <Page
      loading={loading}
      error={error}
      navTitle="Vendor Portal"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle="Quotes"
    >
      <div className="d-flex justify-content-end align-items-center mb-3">
        <FilterSearch
          searchFunction={filterQuotes}
          inline
          placeholder="Search Quotes"
          style={{ minWidth: 300 }}
        />
      </div>
      <SortableTable
        items={filteredQuotes.map((quote) => ({
          ...quote,
          project_name: !!quote.project && quote.project.name,
          project_status: !!quote.project && quote.project.status,
          adjustedStatus: getAdjustedStatus(quote)
        }))}
        config={config}
        fallbackSort="supplier_name"
        url={(item) => `/quotes/${item.id}`}
        sticky
      />
    </Page>
  );
};

export default PurchaseOrders;

import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useFormControl, Joi, useApi } from "component-library";
import PageLoader from "../../_app/_module/PageLoader";
import PropTypes from "prop-types";

const useInviteContact = (addContact, close) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = {
    first_name: Joi.saneString().required().label("First Name"),
    last_name: Joi.saneString().required().label("Last Name"),
    job_title: Joi.saneString().label("Job Title"),
    email: Joi.saneString()
      .required()
      .email({ tlds: { allow: false } })
      .label("Email"),
    mobile_phone: Joi.saneString().label("Mobile Phone"),
    direct_phone: Joi.saneString().label("Direct Phone"),
    ext: Joi.saneString().label("Ext"),
  };

  const initialData = {
    first_name: "",
    last_name: "",
    job_title: "",
    email: "",
    mobile_phone: "",
    direct_phone: "",
    ext: "",
  };

  const onSubmit = async (formData, setSuccess) => {
    addContact({ ...formData, status: "Invited" });
    setSuccess(true);
  };

  const { handleSubmit, renderSubmitButton, renderTextField } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const fields = (
    <div className="position-relative">
      {!!loading && <PageLoader />}
      {!!error && (
        <Alert dismissible variant="danger">
          {error}
        </Alert>
      )}
      <div className="med font-weight-bold mb-1">Contact Info</div>
      <div className="row mb-2">
        <div className="col-md-6">
          {renderTextField({
            name: "first_name",
            label: "First Name",
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          {renderTextField({
            name: "job_title",
            label: "Job Title",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-6">
          {renderTextField({
            name: "direct_phone",
            label: "Direct Phone",
            type: "tel",
            format: "phone",
          })}
        </div>
        <div className="col-md-2">
          {renderTextField({
            name: "ext",
            label: "Ext",
          })}
        </div>
        <div className="col-md-6">
          {renderTextField({
            name: "mobile_phone",
            label: "Mobile Phone",
            type: "tel",
            format: "phone",
          })}
        </div>

        <div className="col-md-8">
          {renderTextField({
            name: "email",
            autoFocus: true,
            label: "Email Address",
          })}
        </div>
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-end text-right mt-4 med">
            <Button className="btn med me-3" variant="gray5" onClick={close}>
              Cancel
            </Button>
            {renderSubmitButton("Add Contact")}
          </div>
        </div>
      </div>
    </div>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    success,
  };
};

useInviteContact.propTypes = {
  addContact: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default useInviteContact;

import React, { useState } from "react";
import Notification from "./Notification";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import BidNotice from "./NewBidsNotice";
import QuoteAwardedNotice from "./QuoteAwardedNotice";

const RecentUpdates = () => {
  const [notifications, setNotifications] = useState([
    {
      user: {
        first_name: "John",
        last_name: "Doe",
      },
      submittal: {
        name: "Submittal 1",
      },
      action: "uploaded",
      file: {
        name: "file 1",
        url: "https://www.google.com",
      },
    },
    {
      user: {
        first_name: "Abernathy",
        last_name: "Bertram",
      },
      submittal: {
        name: "Test Submittal",
      },
      action: "updated",
    },
  ]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.title}>Recent Updates</div>
        <Button
          variant="link"
          className={styles.button}
          onClick={() => setNotifications([])}
        >
          Clear All
        </Button>
      </div>
      <div className={styles.body}>
        <BidNotice number={22} />
        <QuoteAwardedNotice quote={{
          number: "111",

        }} project={
          {
            number: "22",
            name: "Project 22",
          }
        } />
        {notifications.map((notice, index) => (
          <Notification
            key={index}
            user={notice.user}
            action={notice.action}
            file={notice.file}
            submittalName={notice.submittal.name}
          />
        ))}
      </div>
    </div>
  );
};

export default RecentUpdates;

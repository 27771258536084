import React, { useState, useEffect } from "react";
import { DollarSign } from "react-feather";
import Page from "../../../_app/_layout/PrivatePage";
import useSubmittal from "./useSubmittal";
import { Submittal as S, useAuth } from "component-library";
import { Send } from "react-feather";
import { BeatLoader } from "react-spinners";

const Submittal = () => {
  const { config, userCan, user } = useAuth();
  const { setSubmittal, submittal, loading, error, updateStatus } =
    useSubmittal();
  const [hasAccess, setHasAccess] = useState();

  useEffect(() => {
    if (!!submittal && !!user && !!user.contact_id) {
      if (
        submittal.project_owner_id === user.contact_id ||
        submittal.source_id === user.contact_id
      ) {
        setHasAccess(true);
      }

      if (!!submittal.contacts && !!submittal.contacts.length) {
        submittal.contacts.map(
          (c) => c.contact_id === user.contact_id && setHasAccess(true)
        );
      }
    }
  }, [submittal, user]);

  const ActionButtons = () => (
    <>
      {!!submittal &&
      (submittal.status_id === 2 || submittal.status_id === 6) ? (
        <button
          className="position-relative d-flex align-items-center btn btn-link"
          onClick={() => updateStatus()}
          disabled={!hasAccess}
        >
          {!!loading && (
            <div className="loaderWrapper">
              <BeatLoader size="14" color="#0071ce" />
            </div>
          )}
          <Send size="16" className="me-2" />
          Send
        </button>
      ) : (
        <div>{!!submittal && !!submittal.status && submittal.status.name}</div>
      )}
    </>
  );

  return (
    <Page
      loading={loading}
      error={error}
      navTitle="Vendor Portal"
      navIcon={<DollarSign color="#fff" size={14} />}
      pageTitle="Submittal"
      pageSubtitlePrimaryContent={() => (
        <h2 className="lg font-weight-bold">{submittal.name}</h2>
      )}
      pageSubtitleSecondaryContent={ActionButtons}
    >
      {!!hasAccess ? (
        <div className="col-xxl-4">
          <S.SubmittalSpecPlans submittal={submittal} />

          <div className="border-bottom mb-3 pb-4">
            <h5 className="pt-3">Description</h5>
            <p className="med">{submittal.description}</p>
          </div>

          <h5 className="pt-3">Attachments</h5>
          <div className="border-bottom mb-3 pb-4">
            <S.EditAttachments
              updateContent={setSubmittal}
              submittal={submittal}
              multiType
              config={config}
              userCan={userCan}
              isPortal={true}
            />
          </div>
          {!!submittal && !!submittal.requirements && (
            <div className="border-bottom mb-3 pb-4">
              <S.SubmittalRequirements
                submittal={submittal}
                requirements={submittal.requirements}
                setSubmittal={setSubmittal}
                config={config}
                userCan={userCan}
                isPortal={true}
              />
            </div>
          )}
          <div className="border-bottom mb-3 pb-4">
            <S.SubmittalHistory
              submittal={submittal}
              setSubmittal={setSubmittal}
              userCan={userCan}
              isPortal={true}
            />
          </div>
        </div>
      ) : (
        <div>You are not a contact on this submittal</div>
      )}
    </Page>
  );
};

export default Submittal;

import React from "react";

const colors = {
  bid: "#F0C808",
  precon: "#FFA400",
  project: "#7DDB2D",
};

const Status = ({ item }) => {

  return (
    <div className="d-flex">
      <div
        className="q-statusPill"
        style={{
          backgroundColor:
            colors[!!item.project && item.project.status],
        }}
      >
        {!!item.project && item.project.status}
      </div>
    </div>
  );
};

export default Status;

import React from "react";
import Contact from "./Contact";
import ContactButton from "./ContactButton";
import PropTypes from "prop-types";

const Contacts = ({ contacts, openModal }) => {

  return (
    <div className="d-flex flex-row align-items-center flex-wrap">
      {!!contacts &&!!contacts.length && contacts.map((contact, index) => {
        return <Contact contact={contact} key={index} />;
      })}
      <ContactButton openModal={openModal} />
    </div>
  );
}

Contacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  openModal: PropTypes.func.isRequired,
};

export default Contacts;
import React, { useState } from "react";
import { Alert } from "react-bootstrap";
import { userApi, useFormControl, Joi, useApi } from "component-library";
import PageLoader from "../../_app/_module/PageLoader";

const useSetPassword = (token, email) => {
  const [success, setSuccess] = useState(false); 

  const {
    request: contactRequest,
    loading: contactLoading,
    error: contactError,
  } = useApi(userApi.portalContact);

  const schema = {
    first_name: Joi.saneString().required().label("First Name"),
    last_name: Joi.saneString().required().label("Last Name"),
    company: Joi.saneString().required().label("Company"),
    email: Joi.saneString()
      .required()
      .email({ tlds: { allow: false } })
      .label("Email"),
    phone: Joi.saneString().label("Phone"),
    subject: Joi.saneString().label("Subject"),
    comments: Joi.saneString().label("How can we help?")
  };
  
  const initialData = {
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
    subject: '',
    email: '',
    comments: '',
  };

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);
    const success = contactRequest(formData);

    if (!!success) {
      setLoading(false);
      setSuccess(true); 
    }
  };

  const { handleSubmit, renderSubmitButton, renderTextField, } =
    useFormControl({
      schema,
      onSubmit,
      initialData,
    });

  const fields = (
    <div className="position-relative">
      {!!contactLoading && (
        <PageLoader />
      )}
      {!!contactError &&
        <Alert
          dismissible
          variant="danger"
        >
          {contactError}
        </Alert>
      }
      <div className="row mb-2">
        <div className="col-md">
          {renderTextField({
            name: "first_name",
            label: "First Name",
          })}
        </div>
        <div className="col">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-5">
          {renderTextField({
            name: "phone",
            label: "Phone",
            type: "tel",
            format: "phone",
          })}
        </div>
        <div className="col">
          {renderTextField({
            name: "email",
            autoFocus: true,
            label: "Email Address",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          {renderTextField({
            name: "company",
            label: "Company",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          {renderTextField({
            name: "subject",
            label: "Subject",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          {renderTextField({
            name: "comments",
            label: "How can we help you?",
          })}
        </div>
      </div>
      <div className="text-right mt-4">
        {renderSubmitButton("Submit")}
      </div>
    </div>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    renderSubmitButton,
    success,
  };
};

export default useSetPassword;

import React from "react";
import PublicPage from "../../_app/_layout/PublicPage";
import Banner from "../../_app/_module/Banner";
import styles from "./styles.module.scss";
import HasAccountForm from "./HasAccountForm";
import Contact from "./Contact";

function HasAccount() {

  return (
    <div className="landingWrapper">
      <PublicPage isLanding={true}>
        <Banner copy={"Your Company already has an account"} />
        <div className={`${styles.contentWrapper} py-4`}>
          <div className="text-center mb-4 mt-2 px-4">
            Alii autem, quibus ego assentior, cum a natura incorrupte atque corrupti, quos tu tam inportuno tamque crudeli
          </div>
          <HasAccountForm />
          <Contact />
        </div>
        </PublicPage>
      </div>
  );
}

export default HasAccount;

import { Loader } from "component-library";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { X } from "react-feather";
import styles from "../styles.module.scss";
import usePurchaseOrder from "./usePurchaseOrder";

const SignPurchaseOrder = () => {
  const {
    signatureModalOpen,
    setSignatureModalOpen,
    purchaseOrder,
    setCheckingStatus,
  } = usePurchaseOrder();
  return (
    <Modal show={signatureModalOpen} animation={false} dialogClassName={styles.full} className={styles.fullModal}>
      <Modal.Header>
        <h4 className="mb-0">Sign Purchase Order</h4>
        <Button
          onClick={() => {
            setSignatureModalOpen(false);
            setCheckingStatus(true);
          }}
          variant="link"
          className="me-n3 mt-n1"
        >
          <X color="#979797" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Loader color={"#0071ce"} />
        {!!purchaseOrder && !!purchaseOrder.signing_key && signatureModalOpen && (
          <iframe
            className={styles.iframeModal}
            src={`${process.env.REACT_APP_DOCUSIGN_URL}sign/${purchaseOrder.signing_key}`}
            title="Sign Purchase Order"
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SignPurchaseOrder;

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useApi, submittalsApi } from "component-library";

const useSubmittal = () => {
  const { submittalId } = useParams();
  const [submittal, setSubmittal] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const {
    request: getRequest,
    data: getData,
    loading: getLoading,
    error: getError
  } = useApi(submittalsApi.getSubmittal);

  const {
    data: updateData,
    request: updateRequest,
    error: updateError,
    loading: updateLoading
  } = useApi(submittalsApi.updateSubmittal);

  const updateStatus = () => {
    updateRequest({
      id: submittal.id,
      status_id: 3
    });
  }
  
  useEffect(() => {
    if (!!submittalId) {
      getRequest(submittalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittalId]);
  
  useEffect(() => {
    if (getData && getData.submittal) {
      setSubmittal(getData.submittal);
    }
  }, [getData]);

  useEffect(() => {
    if (!!updateData && updateData.submittal) {
      setSubmittal(updateData.submittal);
    }
  }, [updateData])

  useEffect(() => {
    if (!!getLoading || !!updateLoading) {
      setLoading(getLoading || updateLoading)
    } else {
      setLoading();
    }
  }, [getLoading, updateLoading])

  useEffect(() => {
    if (!!getError || !!updateError) {
      setError(getError || updateError);
    } else {
      setError();
    }
  }, [getError, updateError])

  return {
    submittal,
    setSubmittal,
    loading,
    error,
    updateStatus,
  }
}

export default useSubmittal
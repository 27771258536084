import React from "react";
import { Public } from "../_module/Header";
import { Alert } from "react-bootstrap";
import PageLoader from "../Loader";
import PropTypes from "prop-types";
import useLoggedin from "../_hooks/useLoggedIn";
import { Navigate } from "react-router-dom";

const PublicPage = ({
  children,
  title,
  loading,
  error,
  subtitle,
  backLink,
  centered,
  hideNav,
  isLanding = false,
  redirectURL,
}) => {
  const { loggedIn } = useLoggedin();
  return (
    <>
      {loggedIn ? (
        <Navigate to="/dashboard" />
      ) : (
        <>
          {!hideNav && <Public title={title} />}

          {!!error && (
            <div className="container">
              <div className="row my-4">
                <div className="col">
                  <Alert variant="danger">{error}</Alert>
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <PageLoader />
          ) : (
            <>
              <div>
                <main className={`${!isLanding ? "container mb-4" : "landing-container"}`}>
                  {!!subtitle && (
                    <div className="pb-2 row">
                      <div
                        className={`${centered ? "justify-content-center" : "justify-content-between"} col mb-4 d-flex`}
                      >
                        <div>
                          {subtitle && <h4 className="mb-0 page-subtitle">{subtitle}</h4>}
                          {!!backLink && backLink()}
                        </div>
                      </div>
                    </div>
                  )}
                  {children}
                </main>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

PublicPage.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.any,
  backLink: PropTypes.any,
  centered: PropTypes.bool,
  hideNav: PropTypes.bool,
};

export default PublicPage;

import React, { useState, useEffect } from "react";
import Todo from "./Todo";
import { Calendar } from "react-feather";
import { DateTime } from "luxon";
import NoActivity from "./NoActivity";
import PropTypes from "prop-types";

const UpcomingTodos = ({ activeBidsTotal }) => {
  const [todos, setTodos] = useState([]);
  const [sampleTodos] = useState([
    {
      id: 1,
      name: "Lorem ipsum dolor sit amet",
      description: "This is a test description",
      due_date: DateTime.local().plus({ days: 1 }),
      address: "123 Main St, Kansas City, Mo",
    },
    {
      id: 2,
      name: "This is a todo item",
      description: "Lorem ipsum dolor sit amet",
      due_date: DateTime.local().plus({ hours: 2 }),
      flag: true,
    },
    {
      id: 3,
      name: "Bid #3",
      description: "Bid #3 description",
      due_date: DateTime.local().plus({ days: 3 }),
      fileRequired: true,
    },
    {
      id: 4,
      name: "This is a name for a todo item",
      description: "This is a description for a todo item",
      due_date: DateTime.local().plus({ hours: 4 }),
      fileRequired: true,
    },
    {
      id: 5,
      name: "This is another todo item",
      description: "This is a description for a todo item",
      due_date: DateTime.local().plus({ days: 10 }),
      flag: true,
    },
  ]);

  const [todosToday, setTodosToday] = useState([]);
  const [todosThisWeek, setTodosThisWeek] = useState([]);
  const [upcomingTodos, setUpcomingTodos] = useState([]);

  const today = DateTime.local().toFormat("MMMM d, yyyy");

  useEffect(() => {
    if (!!todos.length) {
      const itemsToday = todos.filter((item) => {
        return item.due_date.hasSame(DateTime.local(), "day");
      });
      const itemsThisWeek = todos.filter((item) => {
        return (
          item.due_date.hasSame(DateTime.local(), "week") &&
          !item.due_date.hasSame(DateTime.local(), "day")
        );
      });

      const upcomingItems = todos.filter((item) => {
        return DateTime.fromISO(item.due_date) > DateTime.local().endOf("week");
      });

      setTodosToday(itemsToday);
      setTodosThisWeek(itemsThisWeek);
      setUpcomingTodos(upcomingItems);
    }
  }, [todos]);

  return (
    <>
      {!!todos.length ? (
        <>
          <div className="xl mx-1 my-2 d-flex align-items-center">
            <Calendar color="#0071ce" size={18} className="me-2" />
            <>{today}</>
          </div>
          <div className="row">
            {!!todosToday.length && (
              <div className="col-12 col-lg-6 col-md-12">
                {todosToday.map((todo) => (
                  <Todo
                    item={todo}
                    initalOpen={true}
                    dateString={DateTime.fromISO(todo.due_date).toFormat("h:mm a")}
                    flag={todo.flag}
                    fileRequired={todo.fileRequired}
                    key={todo.id}
                  />
                ))}
              </div>
            )}

            <div className="col">
              {!!todosThisWeek.length && <div className="sm-med mx-1 mb-1">This Week</div>}
              {todosThisWeek.map((todo) => (
                <Todo
                  item={todo}
                  flag={todo.flag}
                  fileRequired={todo.fileRequired}
                  dateString={DateTime.fromISO(todo.due_date).toFormat("ccc")}
                  key={todo.id}
                />
              ))}
              {!!upcomingTodos.length && <div className="sm-med mx-1 mb-1">Upcoming</div>}
              {upcomingTodos.map((todo) => (
                <Todo
                  item={todo}
                  flag={todo.flag}
                  dateString={DateTime.fromISO(todo.due_date).toFormat("MM/dd")}
                  fileRequired={todo.fileRequired}
                  key={todo.id}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <NoActivity activeBidCount={activeBidsTotal} />
      )}
    </>
  );
};

UpcomingTodos.propTypes = {
  activeBidsTotal: PropTypes.number,
};

export default UpcomingTodos;

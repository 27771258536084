import React from "react";
import { RotateCcw } from "react-feather";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const RequoteLink = ({ quote, setOpen }) => {
  return (
    <>
      {!!quote && (
        <Button variant="link" onClick={() => setOpen(true)}>
          <RotateCcw size={16} className="me-2" /> Requote
        </Button>
      )}
    </>
  );
};

RequoteLink.propTypes = {
  quote: PropTypes.object,
  setOpen: PropTypes.func.isRequired
};

export default RequoteLink;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Table } from "react-bootstrap";
import LineItem from "./LineItem";
import styles from "./styles.module.scss";
import { Quote as Q } from "component-library";
import useUpdateLineItem from "./useUpdateLineItem";

const LineItems = ({
  setQuote,
  quote,
}) => {
  const { updateLineItem, updateLineLoading, updateLineError, lineUpdating } =
    useUpdateLineItem(quote, setQuote);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!!quote && !!quote.requote) {
      setTotal(
        quote.requote.line_items.reduce((a, c) => a + c.rfq_quantity * c.base_price, 0)
      );
    }
  }, [quote]);

  return (
    <div className="q-section pt-3">
      <div className="q-label">Line Items</div>
      {!!updateLineError && <Alert variant="danger">{updateLineError}</Alert>}
      <Table className="sortable-table">
        <thead>
          <tr>
            <th>
              <span>Part #</span>
            </th>
            <th>
              <span>Item</span>
            </th>
            <th>
              <span>Original Qty</span>
            </th>
            <th>
              <span>New Qty</span>
            </th>
            <th>
              <span>Unit Price</span>
            </th>
            <th className={`${styles.smallWrapper} text-right`}>
              <span>Ext Price</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {quote.requote.line_items.map((item) => (
            <tr key={item.id} className="position-relative">
              <LineItem
                updateItem={updateLineItem}
                item={item}
                updateLoading={updateLineLoading}
                isUpdating={lineUpdating === item.id}
                quoteItem={quote.line_items.find(li => li.takeoff_id === item.takeoff_id)}
              />
            </tr>
          ))}
        </tbody>
      </Table>
      <Q.Total total={total} />
    </div>
  );
};

LineItems.propTypes = {
  quote: PropTypes.object,
};

export default LineItems;

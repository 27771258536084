import React from "react";
import { ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const QuoteAwardedNotice = ({ quote, project }) => {
  return (
    <div className={styles.quote}>
      <div className={styles.header}>
        <div className="font-weight-bold">Quote Awarded!</div>
        <div>
          <Link to="#">
            Review Now <ArrowRightCircle />
          </Link>
        </div>
      </div>
      <div className={styles.footer}>
        #{quote.number} #{project.number} {project.name}
      </div>
    </div>
  );
};

QuoteAwardedNotice.propTypes = {
  quote: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
};

export default QuoteAwardedNotice;

import React from "react";
import useLogin from "../useLogin";
import styles from "../styles.module.scss";
import { Link } from "react-router-dom";

function LoginForm() {
  const { fields, renderSubmitButton, handleSubmit, data } =
    useLogin();

  return (
    <div className={`${styles["form-wrap"]}`}>
      <form onSubmit={handleSubmit} className={`${styles.form} p-3`}>
        {fields}
        <div className={`text-center mt-2`}>
          {renderSubmitButton("Log In", !data.email || !data.password)}
        </div>
        <div className="text-center"><Link to="/forgot-password" className="text-white sm">Forgot Password?</Link></div>
      </form>
    </div>
  );
}

export default LoginForm;

import React from "react";
import { DateTime } from "luxon";
import styles from "../styles.module.scss";

const PurchaseOrderDate = ({ purchaseOrder }) => (
  <div className={`d-flex align-items-center ${styles.datePicker}`}>
    <label className={styles.dateLabel}>PO Date</label>
    {!!purchaseOrder &&
      !!purchaseOrder.po_date &&
      DateTime.fromISO(purchaseOrder.po_date).toFormat("LL.dd.yy")}
  </div>
);

export default PurchaseOrderDate;

import React from "react";
import Banner from "../../../_app/_module/Banner";
import Page from "../../../_app/_layout/PublicPage";
import { ForgotPasswordForm, useAuth } from "component-library";

const ForgotPassword = () => {
  const { forgotPassword } = useAuth();
  return (
    <Page isLanding={true}>
      <Banner copy="Forgot Password" />
      <ForgotPasswordForm forgotPassword={forgotPassword} />
    </Page>
  );
};

export default ForgotPassword;

import React from "react";
import styles from "./styles.module.scss";

const Status = ({item}) => {
  return (
    <div className={`${styles.statusWrapper} d-flex align-items-center`}>
      <span className={styles.dot} style={{
          backgroundColor: item.statusColor
        }}></span>
      {item.statusName.name}
    </div>
  );
};

export default Status;

import React from "react";
import { Tag, ArrowRightCircle } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const NewBidsNotice = ({ number }) => {
  return (
    <div className={styles.noticeBadge}>
      <div>
        <Tag />
        <span className={styles.number}>{number} new bids</span>
      </div>
      <div>
        <Link to="#">Review Now <ArrowRightCircle /></Link>
      </div>
    </div>
  );
};

NewBidsNotice.propTypes = {
  number: PropTypes.number,
};

export default NewBidsNotice;

import React from "react";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

const ContactButton = ({ openModal }) => {
  return (
    <div
      className={styles.contactButton}
      onClick={() => {
        openModal();
      }}
    >
      Add new Contact +
    </div>
  );
};

ContactButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default ContactButton;

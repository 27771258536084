import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Status from "./../Status";
import { currency, useAuth } from "component-library";
import PropTypes from "prop-types";

const PendingQuotes = ({ quotes }) => {
  // todo = Need quote statuses in config
  const { config } = useAuth();
  return (
    <div className={styles.pendingQuotes}>
      <div className="d-flex align-items-center">
        <div className="xxl pe-3">Pending Quotes</div>
        <Link className="btn btn-link med" to={"#"}>
          View Archive
        </Link>
      </div>
      <Table striped>
        <thead>
          <tr>
            <th>Project Name</th>
            <th
              style={{
                width: "8.5rem",
              }}
            >
              Status
            </th>
            <th
              style={{
                width: "10rem",
              }}
            >
              Quote Status
            </th>
            <th
              style={{
                width: "15rem",
              }}
            >
              Bid AMMT
            </th>
          </tr>
        </thead>
        <tbody>
          {!!quotes.length &&
            quotes.map((quote, index) => (
              <tr key={index}>
                <td className={styles.projectName}>{quote.project_name}</td>
                <td>
                  <Status status={quote.project_status} />
                </td>
                <td>{config.submittal_statuses[quote.quote_status].name}</td>
                <td>{currency(quote.bid_amount)}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

PendingQuotes.propTypes = {
  quotes: PropTypes.array,
};

export default PendingQuotes;

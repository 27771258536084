import React from "react";
import { Link } from "react-router-dom";
import Page from "../_app/_layout/PublicPage";

const Unauthorized = () => {
  return (
    <Page
      title="Unauthorized"
      backLink={() => (
        <Link to="/">&lt; Back</Link>
      )}
    >
      <p>You are not authorized to view this page.</p>
    </Page>
  );
}; 

export default Unauthorized;

import React from "react";
import Page from "../../../_app/_layout/PublicPage";
import Banner from "../../../_app/_module/Banner";
import InfoList from "../../../_app/_module/InfoList/index.js";
import CtaContainer from "./CtaContainer";
import { Bell, TrendingUp, Calendar, Repeat, Lock } from "react-feather";

const ApplicationComplete = () => {
  const itemList = [
    {
      text: "Alii autem, quibus ego assentior, cum a natura incorrupte atque corrupti, quos tu tam inportuno tamque crudeli.",
      icon: <Bell size="16" />
    },
    {
      text: "Si sine causa, nollem me tamen laudandis maioribus meis corrupisti nec in voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <TrendingUp size="16" />
    },
    {
      text: "Sed ut earum rerum hic tenetur a se ipsam voluptatem, quia dolor sit, a sapiente delectus.",
      icon: <Calendar size="16" />
    },
    {
      text: "Si sine causa, nollem me tamen laudandis maioribus meis corrupisti nec in voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <Repeat size="16" />
    },
    {
      text: "Sed ut earum rerum hic tenetur a se ipsam voluptatem, quia dolor sit, a sapiente delectus.",
      icon: <Lock size="16" />
    }
  ];

  return (
    <div className="landingWrapper">
      <Page isLanding={true} title="">
        <Banner copy="Thanks for your application!" />
        <InfoList
          title="Here's what's next:"
          list={itemList}
        />
        <CtaContainer />
      </Page>
    </div>
  );
};

export default ApplicationComplete;

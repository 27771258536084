import React, { useEffect, useState } from "react";
import { useAuth } from "component-library";
import Routes from "./Routes";

function AppLoader() {
  const [ready, setReady] = useState(false);
  const { refreshUser } = useAuth();
  const loadApp = async () => {
    await refreshUser();
    setReady(true);
  };

  useEffect(() => {
    loadApp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!ready) return null;

  return <Routes />;
}

export default AppLoader;

const poListConfig = [
  {
    name: "id",
    label: "PO #",
    sort: "numeric",
    width: "12.8%",
  },
  {
    name: "job_number",
    label: "Project #",
    sort: "numeric",
    width: "13.6%"
  },
  {
    name: "name",
    label: "Project Name",
    sort: "alpha",
    width: "34%",
    isTitle: true,
  },
  {
    name: "sum",
    label: "Amount",
    sort: "numeric",
    width: "16%",
    isCurrency: true,
  },
  {
    name: "invoiced_amount",
    label: "Invoiced",
    sort: "numeric",
    width: "16%",
    isCurrency: true,
  },
  {
    name: "remaining",
    label: "Remaining",
    width: "16%",
    sort: "numeric",
    isCurrency: true,
  },
];

export default poListConfig;

import React from "react";
import Page from "../../_app/_layout/PrivatePage";
import ActiveProjectsHeader from "./ActiveProjectsHeader";
import AllProjects from "./AllProjects";
import RecentUpdates from "./RecentUpdates";
import styles from "./styles.module.scss";
import UpcomingTodos from "./UpcomingTodos";
import PendingQuotes from "./PendingQuotes";
import PaymentNotice from "./PaymentNotice";
import useDashboard from "./hooks/useDashboard";
import AddContact from "./AddContact/AddContact";

const Dashboard = () => {
  const { error,loading, user, dashboardStats, pendingQuotes, activeBidsTotal } = useDashboard();

  return (
    <Page
      className={styles.wrapper}
      pageTitle={`Hello, ${user.first_name}!`}
      navTitle="Dashboard"
      loading={loading}
      error={error}>
      <div className="row">
        <div className="col-12">
          <PaymentNotice />
        </div>
        <div className={`col-lg-8 ${styles.mainContent}`}>
          <div className="pb-3 border-bottom">
            <ActiveProjectsHeader
              activeProjectsNumber={dashboardStats.active_projects}
              quotesNumber={dashboardStats.quotes}
              submittalsNumber={dashboardStats.submittals}
              purchaseOrdersNumber={dashboardStats.purchase_orders}
              subContractsNumber={dashboardStats.subcontracts}
            />
          </div>
          <div className="py-2 border-bottom">
            <UpcomingTodos activeBidsTotal={activeBidsTotal} />
          </div>
          <div className="py-2 border-bottom">
            <AllProjects />
          </div>
          <div className="py-2 border-bottom">
            <PendingQuotes quotes={pendingQuotes} />
          </div>
        </div>
        <div className="col-lg-4">
          <RecentUpdates />
        </div>
        <div className="col-12">
          <AddContact />
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;

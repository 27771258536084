import React from "react";
import styles from "../styles.module.scss";
import { currency } from "component-library";

const Sum = ({ purchaseOrder }) => {
  return (
    <div className={`${styles.section} ${styles.sumSection}`}>
      <div className={styles.sumDisplay}>
        <label className={styles.label}>
          For the Sum of {currency(purchaseOrder.sum)}
        </label>
      </div>
    </div>
  );
};

export default Sum;

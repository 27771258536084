import { useState, useEffect } from "react";
import { useApi, userApi, bidApi, useAuth } from "component-library";

const useDashBoard = () => {
  const { user } = useAuth();

  const [error, setError] = useState(null);
  const [dashboardStats, setDashboardStats] = useState({});
  const [pendingQuotes, setPendingQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeBidsTotal, setActiveBidsTotal] = useState(0);

  const {
    request: getBidsRequest,
    error: getBidsError,
    data: getBidsData,
  } = useApi(bidApi.getBids);

  const {
    request: requestDashbordStats,
    loading: dashbordStatsLoading,
    error: dashboardStatsError,
    data: dashbordStatsData,
  } = useApi(userApi.getDashbordStats);

  const {
    request: requestPendingQuotes,
    loading: pendingQuotesLoading,
    error: pendingQuotesError,
    data: pendingQuotesData,
  } = useApi(userApi.getPendingQuotes);

  useEffect(() => {
    getBidsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dashbordStatsLoading || pendingQuotesLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [dashbordStatsLoading, pendingQuotesLoading]);

  useEffect(() => {
    if (dashboardStatsError) {
      setError(dashboardStatsError);
    } else if (pendingQuotesError) {
      setError(pendingQuotesError);
    } else if (getBidsError) {
      setError(getBidsError);
    } else {
      setError(false);
    }
  }, [dashboardStatsError, pendingQuotesError, getBidsError]);

  useEffect(() => {
    requestDashbordStats(user.id);
    requestPendingQuotes(user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!!dashbordStatsData) {
      setDashboardStats(dashbordStatsData.stats);
    }
  }, [dashbordStatsData]);

  useEffect(() => {
    if (!!pendingQuotesData) {
      setPendingQuotes(pendingQuotesData.quotes);
    }
  }, [pendingQuotesData]);

  useEffect(() => {
    if (!!getBidsData && !!getBidsData.projects) {
      setActiveBidsTotal(getBidsData.projects.length);
    }
  }, [getBidsData]);

  return {
    error,
    loading,
    dashboardStats,
    user,
    pendingQuotes,
    activeBidsTotal,
  };
};

export default useDashBoard;

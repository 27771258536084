import { useEffect, useState } from "react";
import { useApi, quoteApi, useAuth } from "component-library";
import { useParams } from "react-router-dom";

const useQuote = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { quoteId } = useParams();

  const [quote, setQuote] = useState();

  const {
    data,
    loading: getLoading,
    error: getError,
    request,
  } = useApi(quoteApi.getQuote);

  const {
    data: createData,
    loading: createLoading,
    error: createError,
    request: createRequest,
  } = useApi(quoteApi.createRequote);

  const createRequote = () => {
    createRequest(quote.id, user.id, 3);
  };

  useEffect(() => {
    if (quoteId) request(quoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  useEffect(() => {
    if (!!data && !!data.quote) setQuote(data.quote);
  }, [data]);

  useEffect(() => {
    if (!!createData && !!createData.quote) setQuote(createData.quote);
  }, [createData]);

  useEffect(() => {
    if (!!getError || !!createError) {
      setError(getError || createError);
    } else {
      setError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getError, createError]);

  useEffect(() => {
    if (createLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLoading]);

  return {
    quote,
    setQuote,
    error,
    setError,
    getLoading,
    createRequote,
    loading,
    setLoading,
  };
};

export default useQuote;

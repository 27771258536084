import { useEffect } from "react";
import { useApi, quoteApi, useAuth } from "component-library";

const useRequote = (quote, setQuote, setLoading, setError) => {
  const { user } = useAuth();
  const {
    loading,
    request,
    error,
    data: submitData,
  } = useApi(quoteApi.submitRequote);

  const {
    data: updateData,
    loading: updateLoading,
    error: updateError,
    request: updateRequest,
  } = useApi(quoteApi.updateQuote);

  const submitRequote = () => {
    request(quote.requote.id, user.id);
  };

  const updateRequote = (q) => {
    updateRequest(q);
  };

  const updateTerms = (value, field) => {
    updateRequote({
      id: quote.requote.id,
      [field]: value,
    });
  };

  useEffect(() => {
    if (!!updateData && !!updateData.quote) {
      setQuote({
        ...quote,
        requote: updateData.quote,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  useEffect(() => {
    if (!!error && !!updateError) {
      setError(error || updateError);
    } else {
      setError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, updateError]);

  useEffect(() => {
    if (!!loading || !!updateLoading) {
      setLoading(loading || updateLoading);
    } else {
      setLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, updateLoading]);

  return {
    submitRequote,
    updateTerms,
    updateRequote,
    submitData,
  };
};

export default useRequote;

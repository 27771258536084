import React, { useState } from "react";
import { ChevronsRight, DollarSign, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

const PaymentNotice = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      {isOpen && (
        <div className={styles.notice}>
          <div>
            <DollarSign size={16} color="#7DDB2D" />
            <span className="font-weight-bold px-3">Want to get paid faster?</span>
            <Link to="/payment">
              Enroll in ACH payments <ChevronsRight size={16} color="#7B7B7B"/>
            </Link>
          </div>

          <XCircle onClick={() => setIsOpen(false)} color="#7DDB2D" size={16} />
        </div>
      )}
    </>
  );
};

export default PaymentNotice;

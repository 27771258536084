import React from "react";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import styles from "./styles.module.scss";
import PropTypes from "prop-types";

function InfoList({title, list, hasLogo = false, primary=true}) {

  return (
    <div className={`${!!primary && styles.infoWrapper} text-gray2`}>
      {!!hasLogo && (
        <div className="text-center"><Logo /></div>
      )}
      <h2 className={`${!!primary ? "text-center xxxl" : "lg"}  mb-4 font-weight-bold `}>{title}</h2>
      <ul className={`list-unstyled m-0 d-flex justify-content-center flex-column ${styles.infoList}`}>
        {!!list && (
          list.map((item, index) => (
            <li className="d-flex mb-3" key={index}>
              <div className={styles.icon}>{item.icon}</div>
              {item.text}
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

InfoList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  hasLogo: PropTypes.bool,
  primary: PropTypes.bool,
};

export default InfoList;

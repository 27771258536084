import React from "react";
import Page from "../../../_app/_layout/PrivatePage";
import { AccountSettingsPage, useAuth } from "component-library";
import { Save } from "react-feather";
import { HeaderButton } from "component-library";

const AccountSettings = () => {
  const { user, setUser } = useAuth();
  const SaveButton = () => (
    <HeaderButton
      className="btn btn-link text-decoration-none"
      type="submit"
      formId="contact-info-form"
      icon={<Save size="14" color="#0071ce" />}
      text="Save Changes"
    />
  );

  return (
    <div>
      <Page
        className="text-gray2"
        navTitle="Vendor Portal"
        pageTitle="Account Settings"
        pageTitleSecondaryContent={SaveButton}
      >
        <AccountSettingsPage user={user} setUser={setUser} />
      </Page>
    </div>
  );
};

export default AccountSettings;

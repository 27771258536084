import React from "react";
import styles from "./styles.module.scss";
import Proptypes from "prop-types";

const ActiveProjectsHeader = ({
  activeProjectsNumber = 0,
  quotesNumber = 0,
  submittalsNumber = 0,
  purchaseOrdersNumber = 0,
  subContractsNumber = 0,
}) => {
  return (
    <div className={`d-flex justify-content-between align-items-center flex-wrap ${styles.header}`}>
      <div className={`xxxl ${styles.activeProject}`}>
        <span className={styles.numberLarge}>{activeProjectsNumber}</span>
        Active Projects
      </div>
      <div className={`med ${styles.item}`}>
        <span className={styles.number}>{quotesNumber}</span>
        Quotes
      </div>
      <div className={`med ${styles.item}`}>
        <span className={styles.number}>{submittalsNumber}</span>
        Submittals
      </div>
      <div className={`med ${styles.item}`}>
        <span className={styles.number}>{purchaseOrdersNumber}</span>
        Purchase Orders
      </div>
      <div className={`med ${styles.item}`}>
        <span className={styles.number}>{subContractsNumber}</span>
        Subcontracts
      </div>
    </div>
  );
};

ActiveProjectsHeader.propTypes = {
  activeProjectsNumber: Proptypes.number,
  quotesNumber: Proptypes.number,
  submittalsNumber: Proptypes.number,
  purchaseOrdersNumber: Proptypes.number,
  subContractsNumber: Proptypes.number,
};

export default ActiveProjectsHeader;

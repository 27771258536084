import React from "react";
import styles from "./styles.module.scss";

function ContactInfo() {
  return (
    <div className={`${styles.infoContainer} p-4 text-gray2 text-center text-lg-left rounded`}>
      <h2 className="lg text-gray2 font-weight-bold mb-1">Questions?</h2>
      <p className="w-75 mx-auto mx-lg-0">Give us a call or fill out this form and we'll get back to you as soon as we can</p>
      <div>
        <h3 className="lg font-weight-bold mb-1">Anamosa</h3>
        <p>
          13225 Circle Drive, Suite A,<br/>
          Anamosa, IA 52205<br/>
          PO BOX 227<br/>
          <b>Phone: </b>319-462-4435<br/>
          <b>Fax:</b> 319-462-4435
        </p>
      </div>
      <div>
        <h3 className="lg font-weight-bold mb-0">Hiawatha</h3>
        <p>
          1059 Longfellow Dr, Suite A,<br/>
          Hiawatha, IA 52233<br/>
          PO BOX 227<br/>
          <b>Phone: </b>319-462-4435<br/>
        </p>
      </div>
    </div>
  );
}

export default ContactInfo;

import React from "react";
import Page from "../../../_app/_layout/PublicPage";
import Banner from "../../../_app/_module/Banner";
import SetPassword from "../../../_forms/SetPassword";

const ApplicationApproved = () => {
  return (
    <div className="landingWrapper">
      <Page isLanding={true}>
        <Banner copy="Your application has been approved!" />
        <div className="text-center">
          <p className="max-text-wrapper mx-auto mt-4 text-gray2">
            Alii autem, quibus ego assentior, cum a natura incorrupte atque corrupti, quos tu tam inportuno tamque crudeli
          </p>
        </div>
        <SetPassword />
      </Page>
    </div>
  );
};

export default ApplicationApproved;

import React from "react";
import Page from "../../../_app/_layout/PrivatePage";
import { NotificationsSettingsPage, useAuth } from "component-library";
import { Save } from "react-feather";
import { HeaderButton } from "component-library";

const NotificationSettings = () => {
  const { user } = useAuth();

  const SaveButton = () => (
    <HeaderButton
      className="btn btn-link text-decoration-none"
      type="submit"
      formId="notification-settings-form"
      icon={<Save size="14" color="#0071ce" />}
      text="Save Changes"
    />
  );

  return (
    <div>
      <Page
        className="text-gray2"
        navTitle="Vendor Portal"
        pageTitle="Notification Settings"
        pageTitleSecondaryContent={SaveButton}
      >
        <NotificationsSettingsPage user={user} />
      </Page>
    </div>
  );
};

export default NotificationSettings;

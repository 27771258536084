import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useFormControl, Joi, states, useApi, userApi  } from "component-library";
import styles from "./styles.module.scss";
import { Bell, TrendingUp, Calendar, Repeat, Lock, List, User, Briefcase } from "react-feather";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../_app/_module/PageLoader";

const useVendorApplication = () => {
  const history = useNavigate();

  const {
    request: applicationRequest,
    loading: applicationLoading,
    error: applicationError,
  } = useApi(userApi.portalVendorApply);

  const operationTypeOptions = [
    { value: "sub-contractor", name: "Sub-contractor" },
    { value: "supplier", name: "Supplier" },
    { value: "both", name: "Both" },
  ];

  const workCategories = [
    { value: "category-1", name: "Category 1" },
    { value: "category-2", name: "Category 2" },
    { value: "other-category", name: "Other Category" },
    { value: "category-4", name: "Category 4" },
    { value: "category-5", name: "Category 5" },
    { value: "other-category-2", name: "Other Category 2" },
    { value: "other-category-3", name: "Other Category 3" },
  ];

  const initialData = {
    email: "",
    first_name: "",
    last_name: "",
    job_title: "",
    direct_phone: "",
    cell_phone: "",
    ext: "",
    fax: "",
    company_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    main_phone: "",
    general_fax: "",
    office_email: "",
    operation_type: "both",
    company_categories: [],
    disclaimer: 0,
  };

  const schema = {
    email: Joi.saneString().required().label("Email"),
    first_name: Joi.saneString().required().label("First Name"),
    last_name: Joi.saneString().required().label("Last Name"),
    job_title: Joi.saneString().label("Job Title"),
    direct_phone: Joi.saneString().label("Phone"),
    cell_phone: Joi.saneString().label("Cell Phone"),
    ext: Joi.saneString().label("Ext"),
    fax: Joi.saneString().label("Fax"),
    company_name: Joi.saneString().required().label("Company Name"),
    address1: Joi.saneString().required().label("Address"),
    address2: Joi.saneString().label("Address 2"),
    city: Joi.saneString().required().label("City"),
    state: Joi.saneString().required().label("State"),
    zip: Joi.saneString().required().label("Zip"),
    main_phone: Joi.saneString().label("Company Phone"),
    general_fax: Joi.saneString().label("Company Fax"),
    office_email: Joi.saneString()
      .required()
      .email({ tlds: { allow: false } })
      .label("Office Email"),
    operation_type: Joi.saneString().label("Operation Type").required(),
    company_categories: Joi.array().items(Joi.any()).min(1).required().label("Categories").messages({
      "array.min": `Please select at least one category.`,
      "array.base": `Please select at least one category.`,
    }),
    disclaimer: Joi.boolean().invalid(false).valid(1),
  };

  const onSubmit = async (formData, setLoading) => {
    setLoading(true);

    const success = await applicationRequest(formData);

    if (!!success) {
      history(`/application-complete`);
    }
    setLoading(false);
  };

  const itemList = [
    {
      text: "Alii autem, quibus",
      icon: <Bell size="16" />,
    },
    {
      text: "Si sine causa, nollem me tamen laudandis",
      icon: <TrendingUp size="16" />,
    },
    {
      text: "Sed ut earum rerum hic",
      icon: <Calendar size="16" />,
    },
    {
      text: "voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <Repeat size="16" />,
    },
    {
      text: "Sed ut earum rerum hic ",
      icon: <Lock size="16" />,
    },
    {
      text: "Alii autem, quibus",
      icon: <Bell size="16" />,
    },
    {
      text: "Si sine causa, nollem me tamen laudandis",
      icon: <TrendingUp size="16" />,
    },
    {
      text: "Sed ut earum rerum hic",
      icon: <Calendar size="16" />,
    },
    {
      text: "voluptate et voluptatem accusantium doloremque laudantium.",
      icon: <Repeat size="16" />,
    },
    {
      text: "Sed ut earum rerum hic ",
      icon: <Lock size="16" />,
    },
  ];

  const {
    handleSubmit,
    renderSubmitButton,
    renderTextField,
    renderSelectField,
    renderCheckboxInGroup,
    renderCheckbox,
    data,
    setFields,
  } = useFormControl({
    schema,
    onSubmit,
    initialData,
  });

  const [listValues, setListValues] = useState([]);

  const setValuesArray = (values) => {
    setListValues(values);
    setFields({
      ...data,
      company_categories: values,
    });
  };

  const fields = (
    <div className="position-relative container">
      {!!applicationLoading && <PageLoader />}
      {!!applicationError && (
        <Alert dismissible variant="danger">
          {applicationError}
        </Alert>
      )}
      <div className="text-gray2">
        <h2 className="d-flex align-items-center xxxl text-gray2">
          <User className="me-2" color="#0071ce" size="18" />
          Your Info
        </h2>
        <p className="text-gray3">
          Enter your information here. If accepted you will become the admin for this account.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-7">
          {renderTextField({
            name: "email",
            autoFocus: true,
            label: "Email",
          })}
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-6">
          {renderTextField({
            name: "first_name",
            label: "First Name",
          })}
        </div>
        <div className="col-lg-6">
          {renderTextField({
            name: "last_name",
            label: "Last Name",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-9">
          {renderTextField({
            name: "job_title",
            label: "Job Title",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-9 col-lg-7">
          {renderTextField({
            name: "direct_phone",
            label: "Direct Phone",
            type: "tel",
            format: "phone",
          })}
        </div>
        <div className="col-3">
          {renderTextField({
            name: "ext",
            label: "Ext",
            type: "numeric",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7">
          {renderTextField({
            name: "cell_phone",
            label: "Cell Phone",
            type: "tel",
            format: "phone",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7">
          {renderTextField({
            name: "fax",
            label: "Fax",
            type: "tel",
            format: "phone",
          })}
        </div>
      </div>
      <hr className="mb-5" />
      <div>
        <h2 className="d-flex align-items-center xxxl text-gray2">
          <Briefcase className="me-2" color="#0071ce" size="18" />
          Company Info
        </h2>
        <p>
          Enter general information for the company here. If accepted you will be able to add and edit locations later.
        </p>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "company_name",
            label: "Company Name",
          })}
        </div>
      </div>
      <h3 className="font-weight-bold med  text-gray2">Company Address</h3>
      <div className="row">
        <div className="col-lg-11">
          {renderTextField({
            name: "address1",
            label: "Address 1",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-11">
          {renderTextField({
            name: "address2",
            label: "Address 2",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          {renderTextField({
            name: "city",
            label: "City",
          })}
        </div>
        <div className="col state-dropdown">
          {renderSelectField({
            name: "state",
            options: states,
            label: "State",
            emptyOption: "ST",
            isClearable: false,
          })}
        </div>
        <div className="col">
          {renderTextField({
            name: "zip",
            label: "Zip",
          })}
        </div>
      </div>
      <h3 className="font-weight-bold med text-gray2">General Contact Info</h3>
      <p className="text-gray3">Enter general contact information for your company here</p>

      <div className="row">
        <div className="col">
          {renderTextField({
            name: "main_phone",
            label: "Main Phone",
            type: "tel",
            format: "phone",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "general_fax",
            label: "Fax",
            type: "tel",
            format: "phone",
          })}
        </div>
      </div>
      <div className="row">
        <div className="col">
          {renderTextField({
            name: "office_email",
            label: "Office Email",
          })}
        </div>
      </div>
      <hr className="mb-5" />
      <div className="mt-4">
        <h2 className="d-flex align-items-center xxxl text-gray2">
          <List className="me-2" color="#0071ce" size="18" />
          Business Categories
        </h2>
        <p className="text-gray3">Will you be operation as a sub-contractor, supplier, or both?</p>
      </div>
      <div>
        {operationTypeOptions.map((option) => (
          <button
            onClick={() => {
              setFields({
                ...data,
                operation_type: option.value,
              });
            }}
            className={`${styles.operationType} ${data.operation_type === option.value && styles.typeActive}`}
            type="button"
            key={option.value}
          >
            {option.name}
          </button>
        ))}
      </div>
      <div className="mt-5">
        <h3 className="font-weight-bold med text-gray2">Work Categories</h3>
        <p className="text-gray3">Select the service you usually provide. You can edit this later.</p>
      </div>
      <div className="mb-4">
        {workCategories.map((category, index) => (
          <div className={`${styles.groupCheckbox} work-categories my-0`} key={index}>
            {renderCheckboxInGroup({
              groupName: "company_categories",
              name: category.value,
              label: category.name,
              setValuesArray,
              valuesArray: listValues,
            })}
          </div>
        ))}
      </div>
      <div>
        <hr />
        {renderCheckbox({
          name: "disclaimer",
          label: "Disclaimer Si sine causa, nollem, me tamen, laudandis",
        })}
      </div>
      <div className="container mt-4">{renderSubmitButton("Submit Application")}</div>
    </div>
  );
  return {
    initialData,
    schema,
    fields,
    handleSubmit,
    itemList,
  };
};

export default useVendorApplication;

import React from "react";
import { ExternalLink } from "react-feather";

const BidLink = ({ link, label }) => {
  return (
    <a href={link} className="contact-pill mx-2 sm-med" target="_blank" rel="noopener noreferrer">
      <ExternalLink size={14} className="align-self-center me-1" /> {label}
    </a>
  );
};

export default BidLink;

import { DateTime } from "luxon";
import styles from "../styles.module.scss";
import Controls from "./Controls";

const config = [
  {
    name: "name",
    label: "Project Name",
    sort: "alpha",
    width: "25%",
    render: ({ item }) => {
      const date = DateTime.fromISO(item.created_at);
      const isNew = date.diffNow().as("hours") >= -24;

      return (
        <>
          {isNew && <span className={styles.new}>New</span>} {item.name}
        </>
      );
    },
  },
  {
    name: "bid_location",
    label: "Location",
    sort: "alpha",
    width: "15%",
  },

  {
    name: "bid_date",
    label: "Deadline",
    sort: "date",
    width: "20%",
    render: ({ item }) => {
      if (!!item.bid_date) {
        const dueDateString = item.bid_date.substring(0, 10) + " " + item.bid_time;
        return dueDateString;
      } else {
        return "";
      }
    },
  },
  {
    name: "category",
    label: "Category",
    width: "30%",
    render: ({ item }) => (
      <div className={styles.categories}>
        <span className={styles.category}>{item?.category?.name}</span>
      </div>
    ),
  },
  {
    name: "controls",
    label: "",
    render: ({ item }) => <Controls bid={item} />,
    noLink: true,
  },
];

export default config;

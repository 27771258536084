import React, { useState } from "react";
import { Star, Tag } from "react-feather";

const Controls = ({ bid }) => {
  const [favorited, setFavorited] = useState(false);
  const [hasQuote, setHasQuote] = useState(false);

  return (
    <div className="d-flex justify-content-end">
      {hasQuote ? (
        <Tag
          color="#FFA400"
          fill="#FFA400"
          size={20}
          onClick={() => setHasQuote(false)}
        />
      ) : (
        <Tag color="#D3D3D3" size={20} onClick={() => setHasQuote(true)} />
      )}
      {favorited ? (
        <Star
          color="#F0C808"
          fill="#F0C808"
          size={20}
          onClick={() => setFavorited(false)}
        />
      ) : (
        <Star color="#D3D3D3" size={20} onClick={() => setFavorited(true)} />
      )}
    </div>
  );
};

export default Controls;

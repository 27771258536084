import React from "react";
import { Routes, Route } from "react-router";
import PrivateRoute from "../../../_app/PrivateRoute";
import ProvidePurchaseOrder from "./ProvidePurchaseOrder copy";
import PurchaseOrder from "./PurchaseOrder";
import PurchaseOrderPDF from "./PurchaseOrderPDF";

const PurchaseOrderRoutes = () => {
  return (
    <ProvidePurchaseOrder>
      <Routes>
        <Route index element={<PrivateRoute Component={<PurchaseOrder />} />} />
        <Route path="pdf" element={<PrivateRoute Component={<PurchaseOrderPDF />} />} />
      </Routes>
    </ProvidePurchaseOrder>
  );
};

export default PurchaseOrderRoutes;

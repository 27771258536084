import { rfqsApi, useApi } from "component-library";
import React, { useEffect, useState } from 'react';
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const RFQResponse = () => {
  const [message, setMessage] = useState("");
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const { request: interested, data: interestedData } = useApi(rfqsApi.rfqInterested);
  const { request: notInterested, data: notInterestedData } = useApi(rfqsApi.rfqNotInterested);

  useEffect(() => {
    if (!!query.get("vh")) {
      if (query.get("interested") === "1") {
        interested(query.get("vh"));
      } else {
        notInterested(query.get("vh"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!interestedData)
      setMessage(
        "Thank you for your interest. We look forward to receiving your quote."
      );
  }, [interestedData]);

  useEffect(() => {
    if (!!notInterestedData)
      setMessage(
        "Thank you for your response."
      );
  }, [notInterestedData]);

  return <>{!!message && <Alert dismissible variant="success">{message}</Alert>}</>;
}

export default RFQResponse;
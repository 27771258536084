import React, { useState, useEffect } from "react";
import { useAuth } from "component-library";

const useLoggedIn = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (user) setLoggedIn(true);
  }, [user]);

  return {
    loggedIn,
  };
};

export default useLoggedIn;

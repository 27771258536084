import React from "react";
import styles from "./styles.module.scss";
import useSetPassword from "./useSetPassword";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SetPassword() {
  let query = useQuery();
  const {
    fields,
    renderSubmitButton,
    handleSubmit,
    success,
  } =
    useSetPassword(query.get("token"), query.get("email"));

  return (
    <div className={`${styles["form-wrap"]} mx-auto`}>
      <form onSubmit={handleSubmit} className={`${styles.form}`}>
        {!!success ? (
          <p className="text-center xl font-weight-bold mt-3">
            Your password has been set!
          </p>
        ) : (
          <>
            {fields}
            <div className="text-center mt-4">
              {renderSubmitButton("Log in")}
            </div>
          </>
        )}
      </form>
    </div>
  );
}

export default SetPassword;

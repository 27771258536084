import React from "react";
import { Link } from "react-router-dom";

function ContactButton() {

  return (
    <Link to="/contact-us" className={`btn btn-tertiary text-white`}>
      Contact Us
    </Link>
  );
}

export default ContactButton;

import React, { useEffect } from "react";
import { Briefcase } from "react-feather";
import Page from "../../../_app/_layout/PrivatePage";
import styles from "../styles.module.scss";
import { PO } from "component-library";

const PurchaseOrderPDF = () => {
  const { poId, url, loading, error, request } = PO.usePOPDF();

  useEffect(() => {
    if (poId) request(poId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poId]);

  return (
    <Page
      navTitle="Vendor Portal"
      navIcon={<Briefcase color="#fff" size={14} />}
      pageTitle={`Purchase Order #${poId}`}
      fillScreen
      loading={loading}
      error={error}
    >
      {!!url && (
        <iframe
          className={styles.iframe}
          src={url}
          title="Purchase Order PDF"
        />
      )}
    </Page>
  );
};

export default PurchaseOrderPDF;

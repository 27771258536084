import React from "react";
import styles from "./styles.module.scss";
import useHasAccount from "./useHasAccount";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HasAccountForm() {
  let query = useQuery();
  const { fields, renderSubmitButton, handleSubmit, data } =
    useHasAccount(query.get("email"));

  return (
    <form onSubmit={handleSubmit} className={`${styles.form} p-3 mb-5`}>
      <div className="text-center">
        {fields}
        <div className="text-center mt-2">
          {renderSubmitButton("Request Invite", !data.email )}
        </div>
      </div>
    </form>
  );
}

export default HasAccountForm;
